
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { TextField, Checkbox } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import * as moment from 'moment';
import AccomodationDeclaration from 'assets/declarationfiles/accomodationdeclaration.pdf';
import * as service from '../../services/service';

function AccomodationRegistration() {
    const [state, setState] = useState(
        {
            emailid: '', mobileno: '', hotelname: '', address: '', authorizedperson: '', pincode: '',
            areasqmt: '', roomno: '', employeeno: '', msebconsumerno: '', hoteladdress: '', emergencycontactno: '',
            electricitycompanydetails: '', propertyyaxdeptdetails: '', waterbilldeptdetails: '', companyname: ''
        });

    const [regionId, setRegionId] = useState('');
    const [regionData, setRegionData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [applicantType, setApplicantType] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [commencementDate, setCommencementDate] = useState(new Date());
    const [instructions, setInstructions] = useState(false);
    const [rooms, setRooms] = useState(false);
    const [roomSize, setRoomSize] = useState(false);
    const [bathroomSize, setBathroomSize] = useState(false);
    const [sanitaryFixtures, setSanitaryFixtures] = useState(false);
    const [fullTimeOperation, setFullTimeOperation] = useState(false);
    const [elevator, setElevator] = useState(false);
    const [electricity, setElectricity] = useState(false);
    const [emergencyLights, setEmergencyLights] = useState(false);
    const [cctv, setCCTV] = useState(false);
    const [guestAccess, setGuestAccess] = useState(false);
    const [securityGuards, setSecurityGuards] = useState(false);
    const [attachedBathroom, setAttachedBathroom] = useState(false);
    const [runningWater, setRunningWater] = useState(false);
    const [tapsShowers, setTapsShowers] = useState(false);
    const [lounge, setLounge] = useState(false);
    const [reception, setReception] = useState(false);
    const [restRoom, setRestRoom] = useState(false);
    const [guestRoom, setGuestRoom] = useState(false);
    const [kitchen, setKitchen] = useState(false);
    const [uniform, setUniform] = useState(false);
    const [pledge, setPledge] = useState(false);
    const [complaintBook, setComplaintBook] = useState(false);
    const [nodalOfficer, setNodalOfficer] = useState(false);
    const [doctorOnCall, setDoctorOnCall] = useState(false);
    const [policeVerification, setPoliceVerification] = useState(false);
    const [fireDrills, setFireDrills] = useState(false);
    const [firstAidKit, setFirstAidKit] = useState(false);
    const [suite, setSuite] = useState(false);
    const [fbOutlet, setFBOutlet] = useState(false);
    const [ironBoard, setIronBoard] = useState(false);
    const [paidTransportation, setPaidTransportation] = useState(false);
    const [businessCenter, setBusinessCenter] = useState(false);
    const [conference, setConference] = useState(false);
    const [sewageTreatment, setSewageTreatment] = useState(false);
    const [rainwaterHarvesting, setRainwaterHarvesting] = useState(false);
    const [other, setOther] = useState(false);
    const [starCategory, setStarCategory] = useState('');

    const [adharFile, setAdharFile] = useState('');
    const [panFile, setPanFile] = useState('');
    const [businessRegistrationFile, setBusinessRegistrationFile] = useState('');
    const [gstCertificateFile, setGSTCertificateFile] = useState('');
    const [fssaiRegistrationFile, setFSSAIRegistrationFile] = useState('');
    const [buildingCertificateFile, setBuildingCertificateFile] = useState('');
    const [declarationFile, setDeclarationFile] = useState('');
    const [lightBillFile, setLightBillFile] = useState('');
    const [MPCBCertificateFile, setMPCBCertificateFile] = useState('');
    const [fireNOC, setFireNOC] = useState('');

    const [starClassifiedFile, setStarClassifiedFile] = useState('');
    const [propertyTaxFile, setPropertyTaxFile] = useState('');
    const [waterBillFile, setWaterBillFile] = useState('');
    const [electricityBillFile, setElectricityBillFile] = useState('');

    const applicantTypeData = [{ label: "Individual Ownership", value: "Individual Ownership" }, { label: "Company", value: "Company" },
    { label: "Partnership", value: "Partnership" }, { label: "LLP", value: "LLP" }];

    const starCategoryData = [{ label: "One Star", value: "One Star" }, { label: "Two Star", value: "Two Star" },
    { label: "Three Star", value: "Three Star" }, { label: "Four Star", value: "Four Star" }, { label: "Five Star", value: "Five Star" }];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const apiKey = process.env.REACT_APP_API_KEY;
    const navigate = useNavigate();

    const getRegions = (id) => {        
        fetch(
            service.GET_REGION_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.regionId, label: item.region }));
                setRegionData(data2);
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_REGION_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);
            });
    };

    useEffect(() => {
        getRegions(1);
        if (window.sessionStorage.getItem('userData') != null) {
            setState({ ...state, emailid: JSON.parse(window.sessionStorage.getItem('userData')).emailId });
        }
    }, []);

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    // const fileHandler = (event, fileName) => {
    //     const maxSize = 150; // in KB
    //     if (event.target.files[0].size / 1024 > maxSize) {
    //         alert("File size limit 5 MB");
    //     }
    //     else if (fileName === 'PAN') {
    //         setPanFile(event.target.files[0]);
    //     }
    //     else if (fileName === 'Adhar Card') {
    //         setAdharFile(event.target.files[0]);
    //     }
    // }

    const validate = () => {
        let isValid = true;
        const errs = {};
        // const maxSize = 150; // in KB
        // const maxSize1 = 1024 // KB = 1MB
        const maxSize = 5120; // in KB = 5MB
        if (!state.emailid) {
            isValid = false;
            errs[".emailid"] = "Please enter Email Id";
        }
        if (!state.mobileno) {
            isValid = false;
            errs[".mobileno"] = "Please enter Mobile No.";
        }
        if (instructions === false) {
            isValid = false;
            errs[".instructions"] = "Please select";
        }
        if (!state.hotelname) {
            isValid = false;
            errs[".hotelname"] = "Please enter";
        }
        if (!state.authorizedperson) {
            isValid = false;
            errs[".authorizedperson"] = "Please enter";
        }
        if (!state.address) {
            isValid = false;
            errs[".address"] = "Please enter";
        }
        if (!applicantType) {
            isValid = false;
            errs[".applicantType"] = "Please select";
        }
        if (!regionId) {
            isValid = false;
            errs[".region"] = "Please select Region";
        }
        if (!districtId) {
            isValid = false;
            errs[".district"] = "Please select District";
        }
        if (!state.pincode) {
            isValid = false;
            errs[".pincode"] = "Please enter";
        }
        if (!state.areasqmt) {
            isValid = false;
            errs[".areasqmt"] = "Please enter";
        }
        if (!state.roomno) {
            isValid = false;
            errs[".roomno"] = "Please enter";
        }
        if (!state.employeeno) {
            isValid = false;
            errs[".employeeno"] = "Please enter";
        }
        if (!commencementDate) {
            isValid = false;
            errs[".commencementDate"] = "Please enter";
        }
        if (!state.msebconsumerno) {
            isValid = false;
            errs[".msebconsumerno"] = "Please enter";
        }

        if (!panFile) {
            isValid = false;
            errs[".panFile"] = "Please upload file";
        }
        if (!adharFile) {
            isValid = false;
            errs[".adharFile"] = "Please upload file";
        }
        if (!businessRegistrationFile) {
            isValid = false;
            errs[".businessRegistrationFile"] = "Please upload file";
        }
        if (!gstCertificateFile) {
            isValid = false;
            errs[".gstCertificateFile"] = "Please upload file";
        }
        if (!fssaiRegistrationFile) {
            isValid = false;
            errs[".fssaiRegistrationFile"] = "Please upload file";
        }
        if (!buildingCertificateFile) {
            isValid = false;
            errs[".buildingCertificateFile"] = "Please upload file";
        }
        if (!declarationFile) {
            isValid = false;
            errs[".declarationFile"] = "Please upload file";
        }
        if (!lightBillFile) {
            isValid = false;
            errs[".lightBillFile"] = "Please upload file";
        }
        // if (!MPCBCertificateFile) {
        //     isValid = false;
        //     errs[".MPCBCertificateFile"] = "Please upload file";
        // }
        if (panFile && getExtension(panFile.name).toLowerCase() !== 'pdf'
            && getExtension(panFile.name).toLowerCase() !== 'jpg'
            && getExtension(panFile.name).toLowerCase() !== 'jpeg'
            && getExtension(panFile.name).toLowerCase() !== 'png'
            && getExtension(panFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".panFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
        }
        if (adharFile && getExtension(adharFile.name).toLowerCase() !== 'pdf'
            && getExtension(adharFile.name).toLowerCase() !== 'jpg'
            && getExtension(adharFile.name).toLowerCase() !== 'jpeg'
            && getExtension(adharFile.name).toLowerCase() !== 'png'
            && getExtension(adharFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".adharFile"] = "Please upload pdf/jpg/jpeg/png/docx  file";
        }
        if (businessRegistrationFile && getExtension(businessRegistrationFile.name).toLowerCase() !== 'pdf'
            && getExtension(businessRegistrationFile.name).toLowerCase() !== 'jpg'
            && getExtension(businessRegistrationFile.name).toLowerCase() !== 'jpeg'
            && getExtension(businessRegistrationFile.name).toLowerCase() !== 'png'
            && getExtension(businessRegistrationFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".businessRegistrationFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (gstCertificateFile && getExtension(gstCertificateFile.name).toLowerCase() !== 'pdf'
            && getExtension(gstCertificateFile.name).toLowerCase() !== 'jpg'
            && getExtension(gstCertificateFile.name).toLowerCase() !== 'jpeg'
            && getExtension(gstCertificateFile.name).toLowerCase() !== 'png'
            && getExtension(gstCertificateFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".gstCertificateFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (fssaiRegistrationFile && getExtension(fssaiRegistrationFile.name).toLowerCase() !== 'pdf'
            && getExtension(fssaiRegistrationFile.name).toLowerCase() !== 'jpg'
            && getExtension(fssaiRegistrationFile.name).toLowerCase() !== 'jpeg'
            && getExtension(fssaiRegistrationFile.name).toLowerCase() !== 'png'
            && getExtension(fssaiRegistrationFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".fssaiRegistrationFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (buildingCertificateFile && getExtension(buildingCertificateFile.name).toLowerCase() !== 'pdf'
            && getExtension(buildingCertificateFile.name).toLowerCase() !== 'jpg'
            && getExtension(buildingCertificateFile.name).toLowerCase() !== 'jpeg'
            && getExtension(buildingCertificateFile.name).toLowerCase() !== 'png'
            && getExtension(buildingCertificateFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".buildingCertificateFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (declarationFile && getExtension(declarationFile.name).toLowerCase() !== 'pdf'
            && getExtension(declarationFile.name).toLowerCase() !== 'jpg'
            && getExtension(declarationFile.name).toLowerCase() !== 'jpeg'
            && getExtension(declarationFile.name).toLowerCase() !== 'png'
            && getExtension(declarationFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".declarationFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (lightBillFile && getExtension(lightBillFile.name).toLowerCase() !== 'pdf'
            && getExtension(lightBillFile.name).toLowerCase() !== 'jpg'
            && getExtension(lightBillFile.name).toLowerCase() !== 'jpeg'
            && getExtension(lightBillFile.name).toLowerCase() !== 'png'
            && getExtension(lightBillFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".lightBillFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (MPCBCertificateFile && getExtension(MPCBCertificateFile.name).toLowerCase() !== 'pdf'
            && getExtension(MPCBCertificateFile.name).toLowerCase() !== 'jpg'
            && getExtension(MPCBCertificateFile.name).toLowerCase() !== 'jpeg'
            && getExtension(MPCBCertificateFile.name).toLowerCase() !== 'png'
            && getExtension(MPCBCertificateFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".MPCBCertificateFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (fireNOC && getExtension(fireNOC.name).toLowerCase() !== 'pdf'
            && getExtension(fireNOC.name).toLowerCase() !== 'jpg'
            && getExtension(fireNOC.name).toLowerCase() !== 'jpeg'
            && getExtension(fireNOC.name).toLowerCase() !== 'png'
            && getExtension(fireNOC.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".fireNOC"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }

        if (panFile && panFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".panFile"] = "File size limit 5 MB";
        }
        if (adharFile && adharFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".adharFile"] = "File size limit 5 MB";
        }
        if (businessRegistrationFile && businessRegistrationFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".businessRegistrationFile"] = "File size limit 5 MB";
        }
        if (gstCertificateFile && gstCertificateFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".gstCertificateFile"] = "File size limit 5 MB";
        }
        if (fssaiRegistrationFile && fssaiRegistrationFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".fssaiRegistrationFile"] = "File size limit 5 MB";
        }
        if (buildingCertificateFile && buildingCertificateFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".buildingCertificateFile"] = "File size limit 5 MB";
        }
        if (declarationFile && declarationFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".declarationFile"] = "File size limit 5 MB";
        }
        if (lightBillFile && lightBillFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".lightBillFile"] = "File size limit 5 MB";
        }
        if (MPCBCertificateFile && MPCBCertificateFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".MPCBCertificateFile"] = "File size limit 5 MB";
        }
        if (fireNOC && fireNOC.size / 1024 > maxSize) {
            isValid = false;
            errs[".fireNOC"] = "File size limit 5 MB";
        }
        setErrors(errs);
        return isValid;
    }

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const regionChangeHandler = (ev) => {
        setRegionId(ev);
        setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const applicantTypeChangeHandler = (ev) => {
        setApplicantType(ev);
    }
    const handleInstructionsCheckboxChange = (checked) => {
        setInstructions(checked);
    }
    const handleRoomsCheckboxChange = (checked) => {
        setRooms(checked);
    }
    const handleRoomSizeCheckboxChange = (checked) => {
        setRoomSize(checked);
    }
    const handleBathroomSizeCheckboxChange = (checked) => {
        setBathroomSize(checked);
    }
    const handleSanitaryFixturesCheckboxChange = (checked) => {
        setSanitaryFixtures(checked);
    }
    const handleFullTimeOperationCheckboxChange = (checked) => {
        setFullTimeOperation(checked);
    }
    const handleElevatorCheckboxChange = (checked) => {
        setElevator(checked);
    }
    const handleElectricityCheckboxChange = (checked) => {
        setElectricity(checked);
    }
    const handleEmergencyLightsCheckboxChange = (checked) => {
        setEmergencyLights(checked);
    }
    const handleCCTVCheckboxChange = (checked) => {
        setCCTV(checked);
    }
    const handleGuestAccessCheckboxChange = (checked) => {
        setGuestAccess(checked);
    }
    const handleSecurityGuardsCheckboxChange = (checked) => {
        setSecurityGuards(checked);
    }
    const handleAttachedBathroomCheckboxChange = (checked) => {
        setAttachedBathroom(checked);
    }
    const handleRunningWaterCheckboxChange = (checked) => {
        setRunningWater(checked);
    }
    const handleTapsShowersCheckboxChange = (checked) => {
        setTapsShowers(checked);
    }
    const handleLoungeCheckboxChange = (checked) => {
        setLounge(checked);
    }
    const handleReceptionCheckboxChange = (checked) => {
        setReception(checked);
    }
    const handleRestRoomCheckboxChange = (checked) => {
        setRestRoom(checked);
    }
    const handleGuestRoomCheckboxChange = (checked) => {
        setGuestRoom(checked);
    }
    const handleKitchenCheckboxChange = (checked) => {
        setKitchen(checked);
    }
    const handleUniformCheckboxChange = (checked) => {
        setUniform(checked);
    }
    const handlePledgeCheckboxChange = (checked) => {
        setPledge(checked);
    }
    const handleComplaintBookCheckboxChange = (checked) => {
        setComplaintBook(checked);
    }
    const handleNodalOfficerCheckboxChange = (checked) => {
        setNodalOfficer(checked);
    }
    const handleDoctorOnCallCheckboxChange = (checked) => {
        setDoctorOnCall(checked);
    }
    const handlePoliceVerificationCheckboxChange = (checked) => {
        setPoliceVerification(checked);
    }
    const handleFireDrillsCheckboxChange = (checked) => {
        setFireDrills(checked);
    }
    const handleFirstAidKitCheckboxChange = (checked) => {
        setFirstAidKit(checked);
    }
    const handleSuiteCheckboxChange = (checked) => {
        setSuite(checked);
    }
    const handleFBOutletCheckboxChange = (checked) => {
        setFBOutlet(checked);
    }
    const handleIronBoardCheckboxChange = (checked) => {
        setIronBoard(checked);
    }
    const handlePaidTransportationCheckboxChange = (checked) => {
        setPaidTransportation(checked);
    }
    const handleBusinessCenterCheckboxChange = (checked) => {
        setBusinessCenter(checked);
    }
    const handleConferenceCheckboxChange = (checked) => {
        setConference(checked);
    }
    const handleSewageTreatmentCheckboxChange = (checked) => {
        setSewageTreatment(checked);
    }
    const handleRainwaterHarvestingCheckboxChange = (checked) => {
        setRainwaterHarvesting(checked);
    }
    const handleOtherCheckboxChange = (checked) => {
        setOther(checked);
    }

    const starCategoryChangeHandler = (ev) => {
        setStarCategory(ev);
    }

    const backHandler = () => {
        navigate('/authentication/sign-in/basic');
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            let status = "Registration";
            if(JSON.parse(window.sessionStorage.getItem('userData'))){
                status = "Renewal";
            }
            const formData = new FormData();
            formData.append("AccomodationId", 0);
            formData.append("EmailId", state.emailid);
            formData.append("MobileNo", state.mobileno);
            formData.append("HotelName", state.hotelname);
            formData.append("CompanyName", state.companyname);
            formData.append("AuthorizedPerson", state.authorizedperson);
            formData.append("Address", state.address);
            formData.append("HotelAddress", state.hoteladdress);
            formData.append("EmergencyContactNo", state.emergencycontactno);
            formData.append("ApplicantType", applicantType.value);
            formData.append("RegionId", regionId.value);
            formData.append("DistrictId", districtId.value);
            formData.append("Instructions", instructions);
            formData.append("PinCode", state.pincode);
            formData.append("AreaSqMt", state.areasqmt);
            formData.append("RoomNo", state.roomno);
            formData.append("EmployeeNo", state.employeeno);
            formData.append("CommencementDate", (new Date(commencementDate)).toUTCString());
            formData.append("Rooms", rooms);
            formData.append("RoomSize", roomSize);
            formData.append("BathroomSize", bathroomSize);
            formData.append("SanitaryFixtures", sanitaryFixtures);
            formData.append("FullTimeOperation", fullTimeOperation);
            formData.append("Elevator", elevator);
            formData.append("Electricity", electricity);
            formData.append("EmergencyLights", emergencyLights);
            formData.append("CCTV", cctv);
            formData.append("GuestAccess", guestAccess);
            formData.append("SecurityGuards", securityGuards);
            formData.append("AttachedBathroom", attachedBathroom);
            formData.append("RunningWater", runningWater);
            formData.append("TapsShowers", tapsShowers);
            formData.append("Lounge", lounge);
            formData.append("Reception", reception);
            formData.append("RestRoom", restRoom);
            formData.append("GuestRoom", guestRoom);
            formData.append("Kitchen", kitchen);
            formData.append("Uniform", uniform);
            formData.append("Pledge", pledge);
            formData.append("ComplaintBook", complaintBook);
            formData.append("NodalOfficer", nodalOfficer);
            formData.append("DoctorOnCall", doctorOnCall);
            formData.append("PoliceVerification", policeVerification);
            formData.append("FireDrills", fireDrills);
            formData.append("FirstAidKit", firstAidKit);
            formData.append("Suite", suite);
            formData.append("FBOutlet", fbOutlet);
            formData.append("PaidTransportation", paidTransportation);
            formData.append("BusinessCenter", businessCenter);
            formData.append("Conference", conference);
            formData.append("SewageTreatment", sewageTreatment);
            formData.append("RainwaterHarvesting", rainwaterHarvesting);
            formData.append("Other", other);
            formData.append("MSEBConsumerNo", state.msebconsumerno);
            formData.append("StarCategory", starCategory.value);
            formData.append("ElectricityCompanyDetails", state.electricitycompanydetails);
            formData.append("WaterBillDeptDetails", state.waterbilldeptdetails);
            formData.append("PropertyTaxDeptDetails", state.propertyyaxdeptdetails);
            formData.append("PANFile", panFile);
            formData.append("AadharFile", adharFile);
            formData.append("BusinessRegistrationFile", businessRegistrationFile);
            formData.append("GSTCertificateFile", gstCertificateFile);
            formData.append("FSSAIRegistrationFile", fssaiRegistrationFile);
            formData.append("BuildingCertificateFile", buildingCertificateFile);
            formData.append("DeclarationFile", declarationFile);
            formData.append("LightBillFile", lightBillFile);
            formData.append("MPCBCertificate", MPCBCertificateFile);
            formData.append("FireNOC", fireNOC);
            formData.append("StarClassifiedFile", starClassifiedFile);
            formData.append("PropertyTaxFile", propertyTaxFile);
            formData.append("WaterBill", waterBillFile);
            formData.append("ElectricityBill", electricityBillFile);
            formData.append("RenewalStatus", status);

            fetch(
                service.ADD_UPDATE_ACCOMODATION_REGISTRATION,
                {
                    method: 'POST',
                    body: formData,
                    headers: {                        
                        'XApiKey': apiKey
                      },
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert("Congratulations! Your application has been submitted successfully.");
                        navigate('/accomodationsuccess');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <div style={{ width: '100%' }}>
            {/* <DashboardNavbar /> */}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Accomodation Unit Registration For Industrial Status
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan="2" align="center" style={{ fontWeight: 'bold' }}>
                                                    Payment Gateway
                                                    <br />
                                                    To complete the payment visit the following link
                                                    <br />
                                                    <a href="https://gras.mahakosh.gov.in" rel="noreferrer" target="_blank">https://gras.mahakosh.gov.in</a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" align="left" style={{ fontWeight: 'bold' }}>
                                                    * Required
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '45%' }}>
                                                    <MDInput label="Email Id" fullWidth value={state.emailid || ''}
                                                        name="emailid"
                                                        onChange={changeHandler}
                                                        id="emailid"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailid"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Mobile No." fullWidth value={state.mobileno || ''}
                                                        name="mobileno"
                                                        onChange={changeHandler}
                                                        id="mobileno"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mobileno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Name of Hotel and Resort"
                                                        fullWidth value={state.hotelname || ''}
                                                        name="hotelname"
                                                        onChange={changeHandler}
                                                        id="hotelname"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".hotelname"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Hotel Address"
                                                        fullWidth value={state.hoteladdress || ''}
                                                        name="hoteladdress"
                                                        onChange={changeHandler}
                                                        id="hoteladdress"
                                                        multiline
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".hoteladdress"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Company Name" fullWidth value={state.companyname || ''}
                                                        name="companyname"
                                                        onChange={changeHandler}
                                                        id="companyname"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".companyname"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Company Address"
                                                        fullWidth value={state.address || ''}
                                                        name="address"
                                                        onChange={changeHandler}
                                                        id="address"
                                                        multiline
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".address"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Name of Authorized Person" fullWidth value={state.authorizedperson || ''}
                                                        name="authorizedperson"
                                                        onChange={changeHandler}
                                                        id="authorizedperson"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".authorizedperson"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox defaultChecked={instructions} value={instructions}
                                                        onChange={(e) => handleInstructionsCheckboxChange(e.target.checked)}
                                                        id="chkInstructions" />
                                                    The application should be submitted only after complying with all the requirements of the checklist; along with documentary evidence for completion of the same
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".instructions"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select
                                                        options={regionData}
                                                        name="region"
                                                        value={regionId}
                                                        onChange={regionChangeHandler}
                                                        placeholder="Select Region"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".region"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select className="basic-single"
                                                        options={districtData}
                                                        name="district"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                        isSearchable
                                                        placeholder="Select District"
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Pin Code"
                                                        fullWidth value={state.pincode || ''}
                                                        name="pincode"
                                                        onChange={changeHandler}
                                                        id="pincode"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pincode"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        options={applicantTypeData}
                                                        name="applicantType"
                                                        value={applicantType}
                                                        onChange={applicantTypeChangeHandler}
                                                        placeholder="Select Applicant Type"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicantType"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Total Area in Sq.Mt."
                                                        fullWidth value={state.areasqmt || ''}
                                                        name="areasqmt"
                                                        onChange={changeHandler}
                                                        id="areasqmt"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".areasqmt"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Total Number of Rooms" fullWidth value={state.roomno || ''}
                                                        name="roomno"
                                                        onChange={changeHandler}
                                                        id="roomno"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".roomno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Total Number of Employees"
                                                        fullWidth value={state.employeeno || ''}
                                                        name="employeeno"
                                                        onChange={changeHandler}
                                                        id="employeeno"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".employeeno"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    Commencement Date
                                                    <DatePicker
                                                        id="commencementDate"
                                                        selected={commencementDate}
                                                        onChange={date => setCommencementDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                        tabIndex={0}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".commencementDate"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>

                                                <TableCell>
                                                    <MDInput label="Emergency Contact No." fullWidth value={state.emergencycontactno || ''}
                                                        name="emergencycontactno"
                                                        onChange={changeHandler}
                                                        id="emergencycontactno"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emergencycontactno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    General Requirement
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={rooms} value={rooms}
                                                        onChange={(e) => handleRoomsCheckboxChange(e.target.checked)}
                                                        id="chkRooms" />
                                                    Minimum 6 lettable rooms, all rooms with outside windows / ventilation
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={roomSize} value={roomSize}
                                                        onChange={(e) => handleRoomSizeCheckboxChange(e.target.checked)}
                                                        id="chkRoomSize" />
                                                    Minimum Rooms size should be as follows (Room size excludes bathroom) All rooms must have attached bathroom mandatorily - Single - 80 sq. ft. and Double - 120 sq. fL
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={bathroomSize} value={bathroomSize}
                                                        onChange={(e) => handleBathroomSizeCheckboxChange(e.target.checked)}
                                                        id="chkBathroomSize" />
                                                    Minimum Bathroom size should be 30 sq. ft.
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={sanitaryFixtures} value={sanitaryFixtures}
                                                        onChange={(e) => handleSanitaryFixturesCheckboxChange(e.target.checked)}
                                                        id="chkSanitaryFixtures" />
                                                    Bathroom Sanitary Fixtures Toilets must be well ventilated. Each western WC toilet should have a seat with lid and toilet paper. Post toilet hygiene facilities - toilet paper, soap, sanitary bin, 24-hour running water.
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={fullTimeOperation} value={fullTimeOperation}
                                                        onChange={(e) => handleFullTimeOperationCheckboxChange(e.target.checked)}
                                                        id="chkFullTimeOperation" />
                                                    Full time operation 7 days a week
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={elevator} value={elevator}
                                                        onChange={(e) => handleElevatorCheckboxChange(e.target.checked)}
                                                        id="chkElevator" />
                                                    24 hrs. elevators for buildings higher than ground plus four floors or as per the prevailing local building norms applicable
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={electricity} value={electricity}
                                                        onChange={(e) => handleElectricityCheckboxChange(e.target.checked)}
                                                        id="chkElectricity" />
                                                    24x7 availability of electricity
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={emergencyLights} value={emergencyLights}
                                                        onChange={(e) => handleEmergencyLightsCheckboxChange(e.target.checked)}
                                                        id="chkEmergencyLights" />
                                                    Emergency lights available in the public areas
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={cctv} value={cctv}
                                                        onChange={(e) => handleCCTVCheckboxChange(e.target.checked)}
                                                        id="chkCCTV" />
                                                    Hotel Entrances and all common areas are controlled by CCTV Cameras 24 hours a day
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={guestAccess} value={guestAccess}
                                                        onChange={(e) => handleGuestAccessCheckboxChange(e.target.checked)}
                                                        id="chkGuestAccesss" />
                                                    Differently Abled Guest Friendly Access at the entrance
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={securityGuards} value={securityGuards}
                                                        onChange={(e) => handleSecurityGuardsCheckboxChange(e.target.checked)}
                                                        id="chkSecurityGuards" />
                                                    Security Guards available 24 hours a day
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    Bathroom
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={attachedBathroom} value={attachedBathroom}
                                                        onChange={(e) => handleAttachedBathroomCheckboxChange(e.target.checked)}
                                                        id="chkAttachedBathroom" />
                                                    Rooms with attached bathrooms
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={runningWater} value={runningWater}
                                                        onChange={(e) => handleRunningWaterCheckboxChange(e.target.checked)}
                                                        id="chkRunningWater" />
                                                    Availability of Hot and Cold running water
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={tapsShowers} value={tapsShowers}
                                                        onChange={(e) => handleTapsShowersCheckboxChange(e.target.checked)}
                                                        id="chkTapsShowers" />
                                                    Water saving taps and showers
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    Public Area
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={lounge} value={lounge}
                                                        onChange={(e) => handleLoungeCheckboxChange(e.target.checked)}
                                                        id="chkLounge" />
                                                    Lounge or seating area in the lobby
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={reception} value={reception}
                                                        onChange={(e) => handleReceptionCheckboxChange(e.target.checked)}
                                                        id="chkReception" />
                                                    Reception facility
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={restRoom} value={restRoom}
                                                        onChange={(e) => handleRestRoomCheckboxChange(e.target.checked)}
                                                        id="chkRestRoom" />
                                                    Public restrooms with a wash basin, a mirror, a sanitary bin with lid in unisex toilet
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    Room And Facilities for the Differently Abled Guests
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={guestRoom} value={guestRoom}
                                                        onChange={(e) => handleGuestRoomCheckboxChange(e.target.checked)}
                                                        id="chkGuestRoom" />
                                                    Atleast one room for the differently able guest
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    Kitchen / Food Production Area
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={kitchen} value={kitchen}
                                                        onChange={(e) => handleKitchenCheckboxChange(e.target.checked)}
                                                        id="chkKitchen" />
                                                    FSSAI registration / Licensed Kitchen
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    Hotel Staff And Related Facilities
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={uniform} value={uniform}
                                                        onChange={(e) => handleUniformCheckboxChange(e.target.checked)}
                                                        id="chkUniform" />
                                                    Uniforms for front house staff
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    Code of Conduct for Safe And Honorable Tourism
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={pledge} value={pledge}
                                                        onChange={(e) => handlePledgeCheckboxChange(e.target.checked)}
                                                        id="chkPledge" />
                                                    Display of pledge
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={complaintBook} value={complaintBook}
                                                        onChange={(e) => handleComplaintBookCheckboxChange(e.target.checked)}
                                                        id="chkComplaintBook" />
                                                    Maintenance of Complaint book and Suggestion Book
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={nodalOfficer} value={nodalOfficer}
                                                        onChange={(e) => handleNodalOfficerCheckboxChange(e.target.checked)}
                                                        id="chkNodalOfficer" />
                                                    Appointment of Nodal officers and display of information of Nodal officer at reception
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    Guest Services
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={doctorOnCall} value={doctorOnCall}
                                                        onChange={(e) => handleDoctorOnCallCheckboxChange(e.target.checked)}
                                                        id="chkDoctorOnCall" />
                                                    Availability of Doctor-on-call service and name address and telephone number of doctors with front desk
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    Safety And Security
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={policeVerification} value={policeVerification}
                                                        onChange={(e) => handlePoliceVerificationCheckboxChange(e.target.checked)}
                                                        id="chkPoliceVerification" />
                                                    Police Verification for employees
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={fireDrills} value={fireDrills}
                                                        onChange={(e) => handleFireDrillsCheckboxChange(e.target.checked)}
                                                        id="chkFireDrills" />
                                                    Conducting regular fire fighting drills and adherence to norms of the Fire department
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={firstAidKit} value={firstAidKit}
                                                        onChange={(e) => handleFirstAidKitCheckboxChange(e.target.checked)}
                                                        id="chkFirstAidKit" />
                                                    First aid kit at the front desk
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    Additional Features
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={suite} value={suite}
                                                        onChange={(e) => handleSuiteCheckboxChange(e.target.checked)}
                                                        id="chkSuite" />
                                                    Suite (2 rooms or 2 room- bays having a bedroom and separate sitting area, having one bathroom and one powder room)
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={fbOutlet} value={fbOutlet}
                                                        onChange={(e) => handleFBOutletCheckboxChange(e.target.checked)}
                                                        id="chkFBOutlet" />
                                                    F and B Outlet
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={ironBoard} value={ironBoard}
                                                        onChange={(e) => handleIronBoardCheckboxChange(e.target.checked)}
                                                        id="chkIronBoard" />
                                                    Iron and Iron Board facility
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={paidTransportation} value={paidTransportation}
                                                        onChange={(e) => handlePaidTransportationCheckboxChange(e.target.checked)}
                                                        id="chkPaidTransportation" />
                                                    Paid transportation on call
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={businessCenter} value={businessCenter}
                                                        onChange={(e) => handleBusinessCenterCheckboxChange(e.target.checked)}
                                                        id="chkBusinessCenter" />
                                                    Business Center
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={conference} value={conference}
                                                        onChange={(e) => handleConferenceCheckboxChange(e.target.checked)}
                                                        id="chkConference" />
                                                    Conference Facilities
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={sewageTreatment} value={sewageTreatment}
                                                        onChange={(e) => handleSewageTreatmentCheckboxChange(e.target.checked)}
                                                        id="chkSewageTreatment" />
                                                    Sewage Treatment Plant
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={rainwaterHarvesting} value={rainwaterHarvesting}
                                                        onChange={(e) => handleRainwaterHarvestingCheckboxChange(e.target.checked)}
                                                        id="chkRainwaterHarvesting" />
                                                    Rainwater Harvesting
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Checkbox defaultChecked={other} value={other}
                                                        onChange={(e) => handleOtherCheckboxChange(e.target.checked)}
                                                        id="chkOther" />
                                                    Other
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="MSEB Consumer Number"
                                                        fullWidth value={state.msebconsumerno || ''}
                                                        name="msebconsumerno"
                                                        onChange={changeHandler}
                                                        id="msebconsumerno"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".msebconsumerno"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        options={starCategoryData}
                                                        name="starCategory"
                                                        value={starCategory}
                                                        onChange={starCategoryChangeHandler}
                                                        placeholder="Select Star Category"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".starCategory"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Electricity Company Name And Address"
                                                        fullWidth value={state.electricitycompanydetails || ''}
                                                        name="electricitycompanydetails"
                                                        onChange={changeHandler}
                                                        id="electricitycompanydetails"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".electricitycompanydetails"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Property Tax Dept Name And Address"
                                                        fullWidth value={state.propertyyaxdeptdetails || ''}
                                                        name="propertyyaxdeptdetails"
                                                        onChange={changeHandler}
                                                        id="propertyyaxdeptdetails"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".propertyyaxdeptdetails"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Water Bill Dept Name And Address"
                                                        fullWidth value={state.waterbilldeptdetails || ''}
                                                        name="waterbilldeptdetails"
                                                        onChange={changeHandler}
                                                        id="waterbilldeptdetails"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".waterbilldeptdetails"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span> PAN Card (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="panFile"
                                                        type="file"
                                                        onChange={ev => setPanFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".panFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span> Aadhar Card (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="adharFile"
                                                        type="file"
                                                        onChange={ev => setAdharFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".adharFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span> Business Entity Registration Copy (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="businessRegistrationFile"
                                                        type="file"
                                                        onChange={ev => setBusinessRegistrationFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".businessRegistrationFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span> GST Registration Certificate Copy (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="gstCertificateFile"
                                                        type="file"
                                                        onChange={ev => setGSTCertificateFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gstCertificateFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>  Copy of FSSAI registration / Licensed Kitchen (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="fssaiRegistrationFile"
                                                        type="file"
                                                        onChange={ev => setFSSAIRegistrationFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fssaiRegistrationFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>  Copy of completion of building certificate from competent authority OR Building Permission/Sanctioned Plan Copy (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="buildingCertificateFile"
                                                        type="file"
                                                        onChange={ev => setBuildingCertificateFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".buildingCertificateFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span> Declaration Form (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="declarationFile"
                                                        type="file"
                                                        onChange={ev => setDeclarationFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <a href={AccomodationDeclaration} download="Declaration.pdf"> Download Format </a>
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".declarationFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>  Light Bill (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="lightBillFile"
                                                        type="file"
                                                        onChange={ev => setLightBillFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lightBillFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    MPCB Certificate (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="MPCBCertificateFile"
                                                        type="file"
                                                        onChange={ev => setMPCBCertificateFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".MPCBCertificateFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Fire NOC (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="fireNOC"
                                                        type="file"
                                                        onChange={ev => setFireNOC(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fireNOC"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Star Classified Certificate (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="starClassifiedFile"
                                                        type="file"
                                                        onChange={ev => setStarClassifiedFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".starClassifiedFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Property Tax (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="propertyTaxFile"
                                                        type="file"
                                                        onChange={ev => setPropertyTaxFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".propertyTaxFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Water Bill (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="waterBillFile"
                                                        type="file"
                                                        onChange={ev => setWaterBillFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".waterBillFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Electricity Bill (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="electricityBillFile"
                                                        type="file"
                                                        onChange={ev => setElectricityBillFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".electricityBillFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Table style={{ width: '20%' }}>
                                    <TableRow>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                {loading ? <Oval
                                                    heigth="20"
                                                    width="20"
                                                    color='grey'
                                                    ariaLabel='loading'
                                                /> :
                                                    <MDButton color="info" onClick={submitHandler}>
                                                        SAVE
                                                    </MDButton>
                                                }
                                                {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                            </MDBox>
                                        </TableCell>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                <MDButton color="secondary" onClick={backHandler}>
                                                    Back
                                                </MDButton>
                                            </MDBox>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

            <Footer />
        </div>
    );
}

export default AccomodationRegistration;