
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { TextField, Checkbox, Radio } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import { styled } from '@mui/material/styles';
import CaravanDeclaration from 'assets/declarationfiles/caravandeclaration.pdf';
import * as service from '../../services/service';

function CaravanRegistration() {
    const [state, setState] = useState(
        {
            emailid: '', mobileno: '', applicantname: '', address: '', priorexperience: '', vehicleregistrationno: '',
            peopleno: '', bedno: '', routes: '', vehicleengineno: '', vehiclechassisnno: '', emergencycontactno: ''
        });

    const [regionId, setRegionId] = useState('');
    const [regionData, setRegionData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [applicantType, setApplicantType] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [singleAxle, setSingleAxle] = useState(false);
    const [twinAxle, setTwinAxle] = useState(false);
    const [tentTrailer, setTentTraile] = useState(false);
    const [foldingCaravan, setFoldingCaravan] = useState(false);
    const [camperTrailer, setCamperTrailer] = useState(false);

    const [airConditioning, setAirConditioning] = useState(false);
    const [externalBarbeque, setExternalBarbeque] = useState(false);
    const [caravanStabilizer, setCaravanStabilizer] = useState(false);
    const [mobileCharging, setMobileCharging] = useState(false);
    const [gps, setGPS] = useState(false);
    const [electricity, setElectricity] = useState(false);
    const [gas, setGas] = useState(false);
    const [powerWheel, setPowerWheel] = useState(false);
    const [communication, setCommunication] = useState(false);
    const [washingMachine, setWashingMachine] = useState(false);
    const [cycle, setCycle] = useState(false);
    const [tent, setTent] = useState(false);
    const [audioGuide, setAudioGuide] = useState(false);
    const [fridge, setFridge] = useState(false);
    const [washroom, setWashroom] = useState(false);
    const [diningTable, setDiningTable] = useState(false);
    const [tanks, setTanks] = useState(false);
    const [wheelChair, setWheelChair] = useState(false);

    const [challanFile, setChallanFile] = useState('');
    const [vehicleRegistrationFile, setVehicleRegistrationFile] = useState('');
    const [vehicleInsuranceFile, setVehicleInsurance] = useState('');
    const [declarationFile, setDeclarationFile] = useState('');
    const [adharFile, setAdharFile] = useState('');
    const [panFile, setPanFile] = useState('');
    const [vehiclePurchaseFile, setVehiclePurchaseFile] = useState('');
    const [companyDocumentFile, setCompanyDocumentFile] = useState('');

    const applicantTypeData = [{ label: "Individual Ownership", value: "Individual Ownership" }, { label: "Company", value: "Company" },
    { label: "Partnership", value: "Partnership" }, { label: "LLP", value: "LLP" }];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const apiKey = process.env.REACT_APP_API_KEY;
    const navigate = useNavigate();

    const getRegions = (id) => {
        fetch(
            service.GET_REGION_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.regionId, label: item.region }));
                setRegionData(data2);
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_REGION_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);
            });
    };

    useEffect(() => {
        getRegions(1);
        if (window.sessionStorage.getItem('userData') != null) {
            setState({ ...state, emailid: JSON.parse(window.sessionStorage.getItem('userData')).emailId });
        }
    }, []);

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    const validate = () => {
        let isValid = true;
        const errs = {};
        // const maxSize = 150; // in KB
        // const maxSize1 = 1024 // KB = 1MB
        const maxSize = 5120; // in KB = 5MB
        if (!state.emailid) {
            isValid = false;
            errs[".emailid"] = "Please enter Email Id";
        }
        if (!state.mobileno) {
            isValid = false;
            errs[".mobileno"] = "Please enter Mobile No.";
        }
        if (!state.applicantname) {
            isValid = false;
            errs[".applicantname"] = "Please enter Name";
        }
        if (!state.address) {
            isValid = false;
            errs[".address"] = "Please enter Full Address";
        }
        if (!applicantType) {
            isValid = false;
            errs[".applicantType"] = "Please select Applicant Type";
        }
        if (!regionId) {
            isValid = false;
            errs[".region"] = "Please select Region";
        }
        if (!districtId) {
            isValid = false;
            errs[".district"] = "Please select District";
        }
        if (!state.vehicleregistrationno) {
            isValid = false;
            errs[".vehicleregistrationno"] = "Please enter Vehicle Registration No";
        }
        if (!state.peopleno) {
            isValid = false;
            errs[".peopleno"] = "Please enter";
        }
        if (!state.bedno) {
            isValid = false;
            errs[".bedno"] = "Please enter";
        }
        if (!state.routes) {
            isValid = false;
            errs[".routes"] = "Please enter";
        }

        if (!challanFile) {
            isValid = false;
            errs[".challanFile"] = "Please upload file";
        }
        if (!vehicleRegistrationFile) {
            isValid = false;
            errs[".vehicleRegistrationFile"] = "Please upload file";
        }
        if (!vehicleInsuranceFile) {
            isValid = false;
            errs[".vehicleInsuranceFile"] = "Please upload file";
        }
        if (!declarationFile) {
            isValid = false;
            errs[".declarationFile"] = "Please upload file";
        }
        if (!adharFile) {
            isValid = false;
            errs[".adharFile"] = "Please upload file";
        }
        if (!panFile) {
            isValid = false;
            errs[".panFile"] = "Please upload file";
        }
        if (!vehiclePurchaseFile) {
            isValid = false;
            errs[".vehiclePurchaseFile"] = "Please upload file";
        }
        if (!companyDocumentFile) {
            isValid = false;
            errs[".companyDocumentFile"] = "Please upload file";
        }

        if (challanFile && getExtension(challanFile.name).toLowerCase() !== 'pdf'
            && getExtension(challanFile.name).toLowerCase() !== 'jpg'
            && getExtension(challanFile.name).toLowerCase() !== 'jpeg'
            && getExtension(challanFile.name).toLowerCase() !== 'png'
            && getExtension(challanFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".challanFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (vehicleRegistrationFile && getExtension(vehicleRegistrationFile.name).toLowerCase() !== 'pdf'
            && getExtension(vehicleRegistrationFile.name).toLowerCase() !== 'jpg'
            && getExtension(vehicleRegistrationFile.name).toLowerCase() !== 'jpeg'
            && getExtension(vehicleRegistrationFile.name).toLowerCase() !== 'png'
            && getExtension(vehicleRegistrationFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".vehicleRegistrationFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (vehicleInsuranceFile && getExtension(vehicleInsuranceFile.name).toLowerCase() !== 'pdf'
            && getExtension(vehicleInsuranceFile.name).toLowerCase() !== 'jpg'
            && getExtension(vehicleInsuranceFile.name).toLowerCase() !== 'jpeg'
            && getExtension(vehicleInsuranceFile.name).toLowerCase() !== 'png'
            && getExtension(vehicleInsuranceFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".vehicleInsuranceFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (declarationFile && getExtension(declarationFile.name).toLowerCase() !== 'pdf'
            && getExtension(declarationFile.name).toLowerCase() !== 'jpg'
            && getExtension(declarationFile.name).toLowerCase() !== 'jpeg'
            && getExtension(declarationFile.name).toLowerCase() !== 'png'
            && getExtension(declarationFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".declarationFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (adharFile && getExtension(adharFile.name).toLowerCase() !== 'pdf'
            && getExtension(adharFile.name).toLowerCase() !== 'jpg'
            && getExtension(adharFile.name).toLowerCase() !== 'jpeg'
            && getExtension(adharFile.name).toLowerCase() !== 'png'
            && getExtension(adharFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".adharFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (panFile && getExtension(panFile.name).toLowerCase() !== 'pdf'
            && getExtension(panFile.name).toLowerCase() !== 'jpg'
            && getExtension(panFile.name).toLowerCase() !== 'jpeg'
            && getExtension(panFile.name).toLowerCase() !== 'png'
            && getExtension(panFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".panFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (vehiclePurchaseFile && getExtension(vehiclePurchaseFile.name).toLowerCase() !== 'pdf'
            && getExtension(vehiclePurchaseFile.name).toLowerCase() !== 'jpg'
            && getExtension(vehiclePurchaseFile.name).toLowerCase() !== 'jpeg'
            && getExtension(vehiclePurchaseFile.name).toLowerCase() !== 'png'
            && getExtension(vehiclePurchaseFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".vehiclePurchaseFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (companyDocumentFile && getExtension(companyDocumentFile.name).toLowerCase() !== 'pdf'
            && getExtension(companyDocumentFile.name).toLowerCase() !== 'jpg'
            && getExtension(companyDocumentFile.name).toLowerCase() !== 'jpeg'
            && getExtension(companyDocumentFile.name).toLowerCase() !== 'png'
            && getExtension(companyDocumentFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".companyDocumentFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }

        if (challanFile && challanFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".challanFile"] = "File size limit 5 MB";
        }
        if (vehicleRegistrationFile && vehicleRegistrationFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".vehicleRegistrationFile"] = "File size limit 5 MB";
        }
        if (vehicleInsuranceFile && vehicleInsuranceFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".vehicleInsuranceFile"] = "File size limit 5 MB";
        }
        if (declarationFile && declarationFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".declarationFile"] = "File size limit 5 MB";
        }
        if (adharFile && adharFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".adharFile"] = "File size limit 5 MB";
        }
        if (panFile && panFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".panFile"] = "File size limit 5 MB";
        }
        if (vehiclePurchaseFile && vehiclePurchaseFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".vehiclePurchaseFile"] = "File size limit 5 MB";
        }
        if (companyDocumentFile && companyDocumentFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".companyDocumentFile"] = "File size limit 5 MB";
        }
        setErrors(errs);
        return isValid;
    }

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const regionChangeHandler = (ev) => {
        setRegionId(ev);
        setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const applicantTypeChangeHandler = (ev) => {
        setApplicantType(ev);
    }
    const handleSingleAxleCheckboxChange = (checked) => {
        setSingleAxle(checked);
        setTwinAxle(false);
        setTentTraile(false);
        setFoldingCaravan(false);
        setCamperTrailer(false);
    }
    const handleTwinAxleCheckboxChange = (checked) => {       
        setSingleAxle(false);
        setTwinAxle(checked);
        setTentTraile(false);
        setFoldingCaravan(false);
        setCamperTrailer(false);
    }
    const handleTentTrailerCheckboxChange = (checked) => {        
        setSingleAxle(false);
        setTwinAxle(false);
        setTentTraile(checked);
        setFoldingCaravan(false);
        setCamperTrailer(false);
    }
    const handleFoldingCaravanCheckboxChange = (checked) => {        
        setSingleAxle(false);
        setTwinAxle(false);
        setTentTraile(false);
        setFoldingCaravan(checked);
        setCamperTrailer(false);
    }
    const handleCamperTrailerCheckboxChange = (checked) => {        
        setSingleAxle(false);
        setTwinAxle(false);
        setTentTraile(false);
        setFoldingCaravan(false);
        setCamperTrailer(checked);
    }
    const handleAirConditioningCheckboxChange = (checked) => {
        setAirConditioning(checked);
    }
    const handleExternalBarbequeCheckboxChange = (checked) => {
        setExternalBarbeque(checked);
    }
    const handleCaravanStabilizerCheckboxChange = (checked) => {
        setCaravanStabilizer(checked);
    }
    const handleMobileChargingCheckboxChange = (checked) => {
        setMobileCharging(checked);
    }
    const handleGPSCheckboxChange = (checked) => {
        setGPS(checked);
    }
    const handleElectricityCheckboxChange = (checked) => {
        setElectricity(checked);
    }
    const handleGasCheckboxChange = (checked) => {
        setGas(checked);
    }
    const handlePowerWheelCheckboxChange = (checked) => {
        setPowerWheel(checked);
    }
    const handleCommunicationCheckboxChange = (checked) => {
        setCommunication(checked);
    }
    const handleWashingMachineCheckboxChange = (checked) => {
        setWashingMachine(checked);
    }
    const handleCycleCheckboxChange = (checked) => {
        setCycle(checked);
    }
    const handleTentCheckboxChange = (checked) => {
        setTent(checked);
    }
    const handleAudioGuideCheckboxChange = (checked) => {
        setAudioGuide(checked);
    }
    const handleFridgeCheckboxChange = (checked) => {
        setFridge(checked);
    }
    const handleWashroomCheckboxChange = (checked) => {
        setWashroom(checked);
    }
    const handleDiningTableCheckboxChange = (checked) => {
        setDiningTable(checked);
    }
    const handleTanksCheckboxChange = (checked) => {
        setTanks(checked);
    }
    const handleWheelChairCheckboxChange = (checked) => {
        setWheelChair(checked);
    }

    const backHandler = () => {
        navigate('/authentication/sign-in/basic');
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            let status = "Registration";
            if(JSON.parse(window.sessionStorage.getItem('userData'))){
                status = "Renewal";
            }
            const formData = new FormData();
            formData.append("CaravanRegistrationId", 0);
            formData.append("EmailId", state.emailid);
            formData.append("MobileNo", state.mobileno);
            formData.append("ApplicantName", state.applicantname);
            formData.append("Address", state.address);
            formData.append("ApplicantType", applicantType.value);
            formData.append("RegionId", regionId.value);
            formData.append("DistrictId", districtId.value);
            formData.append("SingleAxle", singleAxle);
            formData.append("TwinAxle", twinAxle);
            formData.append("TentTrailer", tentTrailer);
            formData.append("FoldingCaravan", foldingCaravan);
            formData.append("CamperTrailer", camperTrailer);
            formData.append("PriorExperience", state.priorexperience);
            formData.append("VehicleRegistrationNo", state.vehicleregistrationno);
            formData.append("VehicleEngineNo", state.vehicleengineno);
            formData.append("VehicleChassisNo", state.vehiclechassisnno);
            formData.append("EmergencyContactNo", state.emergencycontactno);
            formData.append("PeopleNo", state.peopleno);
            formData.append("BedNo", state.bedno);
            formData.append("AirConditioning", airConditioning);
            formData.append("ExternalBarbeque", externalBarbeque);
            formData.append("CaravanStabilizer", caravanStabilizer);
            formData.append("MobileCharging", mobileCharging);
            formData.append("GPS", gps);
            formData.append("Electricity", electricity);
            formData.append("Gas", gas);
            formData.append("PowerWheel", powerWheel);
            formData.append("Communication", communication);
            formData.append("WashingMachine", washingMachine);
            formData.append("Cycle", cycle);
            formData.append("Tent", tent);
            formData.append("AudioGuide", audioGuide);
            formData.append("Fridge", fridge);
            formData.append("Washroom", washroom);
            formData.append("DiningTable", diningTable);
            formData.append("Tanks", tanks);
            formData.append("WheelChair", wheelChair);
            formData.append("Routes", state.routes);
            formData.append("ChallanFile", challanFile);
            formData.append("VehicleRegistrationFile", vehicleRegistrationFile);
            formData.append("VehicleInsuranceFile", vehicleInsuranceFile);
            formData.append("DeclarationFile", declarationFile);
            formData.append("AadharFile", adharFile);
            formData.append("PANFile", panFile);
            formData.append("VehiclePurchaseFile", vehiclePurchaseFile);
            formData.append("CompanyDocumentFile", companyDocumentFile);
            formData.append("RenewalStatus", status);

            fetch(
                service.ADD_UPDATE_CARAVAN_REGISTRATION,
                {
                    method: 'POST',
                    body: formData,
                    headers: {                  
                        'XApiKey': apiKey
                    },
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert("Congratulations! Your application has been submitted successfully.");
                        navigate('/caravanregistrationsuccess');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));
    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    });

    return (
        <div style={{ width: '100%' }}>
            {/* <DashboardNavbar /> */}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Caravan Registration
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan="2" align="center" style={{ fontWeight: 'bold' }}>
                                                    Department of Tourism has introduced a new policy to promote Caravan Tourism in the state of Maharashtra. With this purpose, we are inviting applicants who intend to get licensed with the state government.
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" align="center" style={{ fontWeight: 'bold' }}>
                                                    Payment Gateway
                                                    <br />
                                                    To complete the payment visit the following link
                                                    <br />
                                                    <a href="https://gras.mahakosh.gov.in" rel="noreferrer" target="_blank">https://gras.mahakosh.gov.in</a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" align="left" style={{ fontWeight: 'bold' }}>
                                                    * Required
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '45%' }}>
                                                    <MDInput label="Email Id" fullWidth value={state.emailid || ''}
                                                        name="emailid"
                                                        onChange={changeHandler}
                                                        id="emailid"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailid"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Mobile No." fullWidth value={state.mobileno || ''}
                                                        name="mobileno"
                                                        onChange={changeHandler}
                                                        id="mobileno"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mobileno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Name of Applicant / Authorized Person"
                                                        fullWidth value={state.applicantname || ''}
                                                        name="applicantname"
                                                        onChange={changeHandler}
                                                        id="applicantname"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicantname"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Full Address" fullWidth value={state.address || ''}
                                                        name="address"
                                                        onChange={changeHandler}
                                                        id="address"
                                                        multiline
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".address"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select
                                                        options={regionData}
                                                        name="region"
                                                        value={regionId}
                                                        onChange={regionChangeHandler}
                                                        placeholder="Select Region"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".region"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select className="basic-single"
                                                        options={districtData}
                                                        name="district"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                        isSearchable
                                                        placeholder="Select District"
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select
                                                        options={applicantTypeData}
                                                        name="applicantType"
                                                        value={applicantType}
                                                        onChange={applicantTypeChangeHandler}
                                                        placeholder="Select Applicant Type"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicantType"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Emergency Contact No."
                                                        fullWidth value={state.emergencycontactno || ''}
                                                        name="emergencycontactno"
                                                        onChange={changeHandler}
                                                        id="emergencycontactno"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emergencycontactno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    Type of Caravan
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Radio
                                                        id="chkSingleAxle"
                                                        checked={singleAxle}
                                                        onChange={(e) => handleSingleAxleCheckboxChange(e.target.checked)}
                                                        value={singleAxle}
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="Single Axle Conventional Caravan"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                    {/* <Checkbox defaultChecked={singleAxle} value={singleAxle}
                                                        onChange={(e) => handleSingleAxleCheckboxChange(e.target.checked)}
                                                        id="chkSingleAxle" /> */}
                                                    Single Axle Conventional Caravan
                                                </TableCell>
                                                <TableCell>
                                                    <Radio
                                                        id="chkTwinAxle"
                                                        checked={twinAxle}
                                                        onChange={(e) => handleTwinAxleCheckboxChange(e.target.checked)}
                                                        value={twinAxle}
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="Twin Axle Caravan"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                    {/* <Checkbox defaultChecked={twinAxle} value={twinAxle}
                                                        onChange={(e) => handleTwinAxleCheckboxChange(e.target.checked)}
                                                        id="chkTwinAxle" /> */}
                                                    Twin Axle Caravan
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Radio
                                                        id="chkTentTrailer"
                                                        checked={tentTrailer}
                                                        onChange={(e) => handleTentTrailerCheckboxChange(e.target.checked)}
                                                        value={tentTrailer}
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="Tent Trailer"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                    {/* <Checkbox defaultChecked={tentTrailer} value={tentTrailer}
                                                        onChange={(e) => handleTentTrailerCheckboxChange(e.target.checked)}
                                                        id="chkTentTrailer" /> */}
                                                    Tent Trailer
                                                </TableCell>
                                                <TableCell>
                                                    <Radio
                                                        id="chkFoldingCaravan"
                                                        checked={foldingCaravan}
                                                        onChange={(e) => handleFoldingCaravanCheckboxChange(e.target.checked)}
                                                        value={foldingCaravan}
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="Folding Caravan"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                    {/* <Checkbox defaultChecked={foldingCaravan} value={foldingCaravan}
                                                        onChange={(e) => handleFoldingCaravanCheckboxChange(e.target.checked)}
                                                        id="chkFoldingCaravan" /> */}
                                                    Folding Caravan
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Radio
                                                        id="chkCamperTrailer"
                                                        checked={camperTrailer}
                                                        onChange={(e) => handleCamperTrailerCheckboxChange(e.target.checked)}
                                                        value={camperTrailer}
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        title="Camper Trailer"
                                                        sx={{
                                                            '&:hover': {
                                                                bgcolor: 'transparent',
                                                            },
                                                        }}
                                                        disableRipple
                                                        color="default"
                                                        checkedIcon={<BpCheckedIcon />}
                                                        icon={<BpIcon />}
                                                    />
                                                    {/* <Checkbox defaultChecked={camperTrailer} value={camperTrailer}
                                                        onChange={(e) => handleCamperTrailerCheckboxChange(e.target.checked)}
                                                        id="chkCamperTrailer" /> */}
                                                    Camper Trailer
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Any Prior Experience in tourism business? If yes, please specify details"
                                                        fullWidth value={state.priorexperience || ''}
                                                        name="priorexperience"
                                                        onChange={changeHandler}
                                                        id="priorexperience"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".priorexperience"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Vehicle Registration Number" fullWidth value={state.vehicleregistrationno || ''}
                                                        name="vehicleregistrationno"
                                                        onChange={changeHandler}
                                                        id="vehicleregistrationno"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".vehicleregistrationno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="How many people can your caravan accomodate?"
                                                        fullWidth value={state.peopleno || ''}
                                                        name="peopleno"
                                                        onChange={changeHandler}
                                                        id="peopleno"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".peopleno"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Number of beds in your caravan"
                                                        fullWidth value={state.bedno || ''}
                                                        name="bedno"
                                                        onChange={changeHandler}
                                                        id="bedno"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".bedno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Vehicle Engine Number" fullWidth value={state.vehicleengineno || ''}
                                                        name="vehicleengineno"
                                                        onChange={changeHandler}
                                                        id="vehicleengineno"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".vehicleengineno"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Vehicle Chasiis Number" fullWidth value={state.vehiclechassisnno || ''}
                                                        name="vehiclechassisnno"
                                                        onChange={changeHandler}
                                                        id="vehiclechassisnno"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".vehiclechassisnno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    Facilities / Amenities if Available
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={airConditioning} value={airConditioning}
                                                        onChange={(e) => handleAirConditioningCheckboxChange(e.target.checked)}
                                                        id="chkAirConditioning" />
                                                    Air Conditioning
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox defaultChecked={externalBarbeque} value={externalBarbeque}
                                                        onChange={(e) => handleExternalBarbequeCheckboxChange(e.target.checked)}
                                                        id="chkExternalBarbeque" />
                                                    External Barbeque Points
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={caravanStabilizer} value={caravanStabilizer}
                                                        onChange={(e) => handleCaravanStabilizerCheckboxChange(e.target.checked)}
                                                        id="chkCaravanStabilizer" />
                                                    Caravan Stabilizer
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox defaultChecked={mobileCharging} value={mobileCharging}
                                                        onChange={(e) => handleMobileChargingCheckboxChange(e.target.checked)}
                                                        id="chkMobileCharging" />
                                                    Mobile Charging SLot
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={gps} value={gps}
                                                        onChange={(e) => handleGPSCheckboxChange(e.target.checked)}
                                                        id="chkgps" />
                                                    GPS System
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox defaultChecked={electricity} value={electricity}
                                                        onChange={(e) => handleElectricityCheckboxChange(e.target.checked)}
                                                        id="chkElectricity" />
                                                    Convenience of supplying electricity through bettery or external hook up
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={gas} value={gas}
                                                        onChange={(e) => handleGasCheckboxChange(e.target.checked)}
                                                        id="chkgas" />
                                                    Gas / Electric Water Heater
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox defaultChecked={powerWheel} value={powerWheel}
                                                        onChange={(e) => handlePowerWheelCheckboxChange(e.target.checked)}
                                                        id="chkPowerWheel" />
                                                    Power wheel mover System for caravan parking (Integrated or clip-on)
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={communication} value={communication}
                                                        onChange={(e) => handleCommunicationCheckboxChange(e.target.checked)}
                                                        id="chkCommunication" />
                                                    Separate arrangement for communication between drivers and passengers in large caravans
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    Optional Features
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={washingMachine} value={washingMachine}
                                                        onChange={(e) => handleWashingMachineCheckboxChange(e.target.checked)}
                                                        id="chkWashingMachine" />
                                                    Washing Machine and clothes drawer
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox defaultChecked={cycle} value={cycle}
                                                        onChange={(e) => handleCycleCheckboxChange(e.target.checked)}
                                                        id="chkCycle" />
                                                    Convenience to keep the cycle behind the caravan
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={tent} value={tent}
                                                        onChange={(e) => handleTentCheckboxChange(e.target.checked)}
                                                        id="chkTent" />
                                                    Tent
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox defaultChecked={audioGuide} value={audioGuide}
                                                        onChange={(e) => handleAudioGuideCheckboxChange(e.target.checked)}
                                                        id="chkAudioGuide" />
                                                    Audio Guide
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={fridge} value={fridge}
                                                        onChange={(e) => handleFridgeCheckboxChange(e.target.checked)}
                                                        id="chkFridge" />
                                                    Fridge and Microwave
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox defaultChecked={washroom} value={washroom}
                                                        onChange={(e) => handleWashroomCheckboxChange(e.target.checked)}
                                                        id="chkWashroom" />
                                                    Washroom with sufficient water supply along with shower and hand shower facility
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={diningTable} value={diningTable}
                                                        onChange={(e) => handleDiningTableCheckboxChange(e.target.checked)}
                                                        id="chkDiningTable" />
                                                    Dining Table
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox defaultChecked={tanks} value={tanks}
                                                        onChange={(e) => handleTanksCheckboxChange(e.target.checked)}
                                                        id="chkTanks" />
                                                    Separate Tanks for grey water (wash water) and black water (sewage)
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={wheelChair} value={wheelChair}
                                                        onChange={(e) => handleWheelChairCheckboxChange(e.target.checked)}
                                                        id="chkWheelChair" />
                                                    WheelChair Facility
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <MDInput label="List down all the routes/circuits that you cover or intend to cover as a part of your Caravan Trip"
                                                        fullWidth value={state.routes || ''}
                                                        name="routes"
                                                        onChange={changeHandler}
                                                        id="routes"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".routes"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span> Registration Fee Challan (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="challanFile"
                                                        type="file"
                                                        onChange={ev => setChallanFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".challanFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>Vehicle Registration Card Document (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="vehicleRegistrationFile"
                                                        type="file"
                                                        onChange={ev => setVehicleRegistrationFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".vehicleRegistrationFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>Vehicle Insurance (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="vehicleInsuranceFile"
                                                        type="file"
                                                        onChange={ev => setVehicleInsurance(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".vehicleInsuranceFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>Declaration Form (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="declarationFile"
                                                        type="file"
                                                        onChange={ev => setDeclarationFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <a href={CaravanDeclaration} download="Declaration.pdf"> Download Format </a>
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".declarationFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>Aadhar Card (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="adharFile"
                                                        type="file"
                                                        onChange={ev => setAdharFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".adharFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>PAN Card (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="panFile"
                                                        type="file"
                                                        onChange={ev => setPanFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".panFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span> Vehicle Purchase Copy (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="vehiclePurchaseFile"
                                                        type="file"
                                                        onChange={ev => setVehiclePurchaseFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".vehiclePurchaseFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span> Proof of Company Documents / Partnership Deed / LLP Deed (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="companyDocumentFile"
                                                        type="file"
                                                        onChange={ev => setCompanyDocumentFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".companyDocumentFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            {/* <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Download Declaration Form
                                                    <a href={CaravanDeclaration} download="Declaration.pdf"> Download Here </a>
                                                </TableCell>
                                            </TableRow> */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Table style={{ width: '20%' }}>
                                    <TableRow>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                {loading ? <Oval
                                                    heigth="20"
                                                    width="20"
                                                    color='grey'
                                                    ariaLabel='loading'
                                                /> :
                                                    <MDButton color="info" onClick={submitHandler}>
                                                        SAVE
                                                    </MDButton>
                                                }
                                                {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                            </MDBox>
                                        </TableCell>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                <MDButton color="secondary" onClick={backHandler}>
                                                    Back
                                                </MDButton>
                                            </MDBox>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

            <Footer />
        </div>
    );
}

export default CaravanRegistration;