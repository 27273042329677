
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { TextField, Checkbox } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import AgroTourDeclaration from 'assets/declarationfiles/agrotourdeclaration.pdf';
import * as service from '../../services/service';

function AgroTourRegistration() {
    const [state, setState] = useState(
        {
            emailid: '', mobileno: '', fullname: '', companyname: '', applicantaddress: '', centeraddress: '', landdescription: '', centerstarted: '',
            documentverifiedstatus: ''
        });

    const [regionId, setRegionId] = useState('');
    const [regionData, setRegionData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [applicantType, setApplicantType] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [dayTripService, setDayTripService] = useState(false);
    const [accomodation, setAccomodation] = useState(false);
    const [playArea, setPlayArea] = useState(false);
    const [adventureGames, setAdventureGames] = useState(false);
    const [ruralGames, setRuralGames] = useState(false);
    const [agriculturalCamping, setAgriculturalCamping] = useState(false);
    const [horticultureSale, setHorticultureSale] = useState(false);
    const [liveInPlace, setLiveInPlace] = useState(false);
    const [greenHouse, setGreenHouse] = useState(false);
    const [milkBusiness, setMilkBusiness] = useState(false);
    const [fisheries, setFisheries] = useState(false);
    const [ropVatikaNursery, setRopVatikaNursery] = useState(false);
    const [animalBirdRearing, setAnimalBirdRearing] = useState(false);
    const [natureAdventureTourism, setNatureAdventureTourism] = useState(false);
    const [other, setOther] = useState(false);

    const [signatureFile, setSignatureFile] = useState('');
    const [landFile, setLandFile] = useState('');
    const [certificateOfRegistrationFile, setCertificateOfRegistrationFile] = useState('');
    const [authorizationLetterFile, setAuthorizationLetterFile] = useState('');
    const [adharFile, setAdharFile] = useState('');
    const [panFile, setPanFile] = useState('');
    const [challanFile, setChallanFile] = useState('');
    const [elecricityBillFile, setElecricityBillFile] = useState('');
    const [licenceFile, setLicenceFile] = useState('');
    const [buildingPermissionFile, setBuildingPermissionFile] = useState('');
    const [declarationFile, setDeclarationFile] = useState('');
    const [zoneCertificate, setZoneCertificateFile] = useState('');

    const applicantTypeData = [{ label: "Personal Ownership", value: "Personal Ownership" }, { label: "Company", value: "Company" },
    { label: "Co-operative Society", value: "Co-operative Society" }, { label: "Partnership", value: "Partnership" },
    { label: "Trust", value: "Trust" }];
    const liveInPlaceData = [{ label: "Yes", value: true }, { label: "No", value: false }];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const navigate = useNavigate();
    const apiKey = process.env.REACT_APP_API_KEY;
    const getRegions = (id) => {
        fetch(
            service.GET_REGION_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.regionId, label: item.region }));
                setRegionData(data2);
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_REGION_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    RegionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);
            });
    };

    useEffect(() => {
        getRegions(1);
        if (window.sessionStorage.getItem('userData') != null) {
            setState({ ...state, emailid: JSON.parse(window.sessionStorage.getItem('userData')).emailId });
        }
    }, []);

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    const validate = () => {
        let isValid = true;
        const errs = {};
        // const maxSize = 150; // in KB
        const maxSize = 5120; // in KB = 5MB
        if (!state.emailid) {
            isValid = false;
            errs[".emailid"] = "Please enter Email Id";
        }
        if (!state.mobileno) {
            isValid = false;
            errs[".mobileno"] = "Please enter Mobile No.";
        }
        if (!state.fullname) {
            isValid = false;
            errs[".fullname"] = "Please enter Name";
        }
        if (!state.companyname) {
            isValid = false;
            errs[".companyname"] = "Please enter Center Name";
        }
        if (!applicantType) {
            isValid = false;
            errs[".applicantType"] = "Please select Applicant Type";
        }
        if (!state.applicantaddress) {
            isValid = false;
            errs[".applicantaddress"] = "Please enter Full Address";
        }
        if (!regionId) {
            isValid = false;
            errs[".region"] = "Please select Region";
        }
        if (!districtId) {
            isValid = false;
            errs[".district"] = "Please select District";
        }
        if (!state.landdescription) {
            isValid = false;
            errs[".landdescription"] = "Please enter Description";
        }
        if (!liveInPlace) {
            isValid = false;
            errs[".liveInPlace"] = "Please select";
        }
        if (!signatureFile) {
            isValid = false;
            errs[".signatureFile"] = "Please upload file";
        }
        if (!landFile) {
            isValid = false;
            errs[".landFile"] = "Please upload file";
        }
        // if (!certificateOfRegistrationFile) {
        //     isValid = false;
        //     errs[".certificateOfRegistrationFile"] = "Please upload file";
        // }
        // if (!authorizationLetterFile) {
        //     isValid = false;
        //     errs[".authorizationLetterFile"] = "Please upload file";
        // }
        if (!panFile) {
            isValid = false;
            errs[".panFile"] = "Please upload file";
        }
        if (!adharFile) {
            isValid = false;
            errs[".adharFile"] = "Please upload file";
        }
        if (!challanFile) {
            isValid = false;
            errs[".challanFile"] = "Please upload file";
        }
        if (!elecricityBillFile) {
            isValid = false;
            errs[".elecricityBillFile"] = "Please upload file";
        }
        if (!licenceFile) {
            isValid = false;
            errs[".licenceFile"] = "Please upload file";
        }
        if (!declarationFile) {
            isValid = false;
            errs[".declarationFile"] = "Please upload file";
        }
        // if (!buildingPermissionFile) {
        //     isValid = false;
        //     errs[".buildingPermissionFile"] = "Please upload file";
        // }
        if (signatureFile && getExtension(signatureFile.name).toLowerCase() !== 'pdf'
            && getExtension(signatureFile.name).toLowerCase() !== 'jpg'
            && getExtension(signatureFile.name).toLowerCase() !== 'jpeg'
            && getExtension(signatureFile.name).toLowerCase() !== 'png'
            && getExtension(signatureFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".signatureFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (landFile && getExtension(landFile.name).toLowerCase() !== 'pdf'
            && getExtension(landFile.name).toLowerCase() !== 'jpg'
            && getExtension(landFile.name).toLowerCase() !== 'jpeg'
            && getExtension(landFile.name).toLowerCase() !== 'png'
            && getExtension(landFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".landFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (certificateOfRegistrationFile && getExtension(certificateOfRegistrationFile.name).toLowerCase() !== 'pdf'
            && getExtension(certificateOfRegistrationFile.name).toLowerCase() !== 'jpg'
            && getExtension(certificateOfRegistrationFile.name).toLowerCase() !== 'jpeg'
            && getExtension(certificateOfRegistrationFile.name).toLowerCase() !== 'png'
            && getExtension(certificateOfRegistrationFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".certificateOfRegistrationFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (authorizationLetterFile && getExtension(authorizationLetterFile.name).toLowerCase() !== 'pdf'
            && getExtension(authorizationLetterFile.name).toLowerCase() !== 'jpg'
            && getExtension(authorizationLetterFile.name).toLowerCase() !== 'jpeg'
            && getExtension(authorizationLetterFile.name).toLowerCase() !== 'png'
            && getExtension(authorizationLetterFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".authorizationLetterFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (panFile && getExtension(panFile.name).toLowerCase() !== 'pdf'
            && getExtension(panFile.name).toLowerCase() !== 'jpg'
            && getExtension(panFile.name).toLowerCase() !== 'jpeg'
            && getExtension(panFile.name).toLowerCase() !== 'png'
            && getExtension(panFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".panFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (adharFile && getExtension(adharFile.name).toLowerCase() !== 'pdf'
            && getExtension(adharFile.name).toLowerCase() !== 'jpg'
            && getExtension(adharFile.name).toLowerCase() !== 'jpeg'
            && getExtension(adharFile.name).toLowerCase() !== 'png'
            && getExtension(adharFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".adharFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (challanFile && getExtension(challanFile.name).toLowerCase() !== 'pdf'
            && getExtension(challanFile.name).toLowerCase() !== 'jpg'
            && getExtension(challanFile.name).toLowerCase() !== 'jpeg'
            && getExtension(challanFile.name).toLowerCase() !== 'png'
            && getExtension(challanFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".challanFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (elecricityBillFile && getExtension(elecricityBillFile.name).toLowerCase() !== 'pdf'
            && getExtension(elecricityBillFile.name).toLowerCase() !== 'jpg'
            && getExtension(elecricityBillFile.name).toLowerCase() !== 'jpeg'
            && getExtension(elecricityBillFile.name).toLowerCase() !== 'png'
            && getExtension(elecricityBillFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".elecricityBillFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (licenceFile && getExtension(licenceFile.name).toLowerCase() !== 'pdf'
            && getExtension(licenceFile.name).toLowerCase() !== 'jpg'
            && getExtension(licenceFile.name).toLowerCase() !== 'jpeg'
            && getExtension(licenceFile.name).toLowerCase() !== 'png'
            && getExtension(licenceFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".licenceFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        if (declarationFile && getExtension(declarationFile.name).toLowerCase() !== 'pdf'
            && getExtension(declarationFile.name).toLowerCase() !== 'jpg'
            && getExtension(declarationFile.name).toLowerCase() !== 'jpeg'
            && getExtension(declarationFile.name).toLowerCase() !== 'png'
            && getExtension(declarationFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".declarationFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }

        if (buildingPermissionFile && getExtension(buildingPermissionFile.name).toLowerCase() !== 'pdf'
            && getExtension(buildingPermissionFile.name).toLowerCase() !== 'jpg'
            && getExtension(buildingPermissionFile.name).toLowerCase() !== 'jpeg'
            && getExtension(buildingPermissionFile.name).toLowerCase() !== 'png'
            && getExtension(buildingPermissionFile.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".buildingPermissionFile"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }

        if (zoneCertificate && getExtension(zoneCertificate.name).toLowerCase() !== 'pdf'
            && getExtension(zoneCertificate.name).toLowerCase() !== 'jpg'
            && getExtension(zoneCertificate.name).toLowerCase() !== 'jpeg'
            && getExtension(zoneCertificate.name).toLowerCase() !== 'png'
            && getExtension(zoneCertificate.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".zoneCertificate"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }

        if (signatureFile && signatureFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".signatureFile"] = "File size limit 5 MB";
        }
        if (landFile && landFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".landFile"] = "File size limit 5 MB";
        }
        if (certificateOfRegistrationFile && certificateOfRegistrationFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".certificateOfRegistrationFile"] = "File size limit 5 MB";
        }
        if (authorizationLetterFile && authorizationLetterFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".authorizationLetterFile"] = "File size limit 5 MB";
        }
        if (panFile && panFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".panFile"] = "File size limit 5 MB";
        }
        if (adharFile && adharFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".adharFile"] = "File size limit 5 MB";
        }
        if (challanFile && challanFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".challanFile"] = "File size limit 5 MB";
        }
        if (elecricityBillFile && elecricityBillFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".elecricityBillFile"] = "File size limit 5 MB";
        }
        if (licenceFile && licenceFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".licenceFile"] = "File size limit 5 MB";
        }
        if (declarationFile && declarationFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".declarationFile"] = "File size limit 5 MB";
        }
        if (buildingPermissionFile && buildingPermissionFile.size / 1024 > maxSize) {
            isValid = false;
            errs[".buildingPermissionFile"] = "File size limit 5 MB";
        }
        if (zoneCertificate && zoneCertificate.size / 1024 > maxSize) {
            isValid = false;
            errs[".zoneCertificate"] = "File size limit 5 MB";
        }
        setErrors(errs);
        return isValid;
    }

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const regionChangeHandler = (ev) => {
        setRegionId(ev);
        setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
    }
    const applicantTypeChangeHandler = (ev) => {
        setApplicantType(ev);
    }
    const liveInPlaceChangeHandler = (ev) => {
        setLiveInPlace(ev);
    }
    const handleDayTripServiceCheckboxChange = (checked) => {
        setDayTripService(checked);
    }
    const handleAccomodationCheckboxChange = (checked) => {
        setAccomodation(checked);
    }
    const handlePlayAreaCheckboxChange = (checked) => {
        setPlayArea(checked);
    }
    const handleAdventureGamesCheckboxChange = (checked) => {
        setAdventureGames(checked);
    }
    const handleRuralGamesCheckboxChange = (checked) => {
        setRuralGames(checked);
    }
    const handleAgriculturalCampingCheckboxChange = (checked) => {
        setAgriculturalCamping(checked);
    }
    const handleHorticultureSaleCheckboxChange = (checked) => {
        setHorticultureSale(checked);
    }
    const handleGreenHouseCheckboxChange = (checked) => {
        setGreenHouse(checked);
    }
    const handleMilkBusinessCheckboxChange = (checked) => {
        setMilkBusiness(checked);
    }
    const handleFisheriesCheckboxChange = (checked) => {
        setFisheries(checked);
    }
    const handleRopVatikaNurseryCheckboxChange = (checked) => {
        setRopVatikaNursery(checked);
    }
    const handleAnimalBirdRearingCheckboxChange = (checked) => {
        setAnimalBirdRearing(checked);
    }
    const handleNatureAdventureTourismCheckboxChange = (checked) => {
        setNatureAdventureTourism(checked);
    }
    const handleOtherCheckboxChange = (checked) => {
        setOther(checked);
    }

    const backHandler = () => {
        navigate('/authentication/sign-in/basic');
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            let status = "Registration";
            if(JSON.parse(window.sessionStorage.getItem('userData'))){
                status = "Renewal";
            }
            const formData = new FormData();
            formData.append("AgroRegistrationId", 0);
            formData.append("EmailId", state.emailid);
            formData.append("MobileNo", state.mobileno);
            formData.append("FullName", state.fullname);
            formData.append("CompanyName", state.companyname);
            formData.append("ApplicantType", applicantType.value);
            formData.append("ApplicantAddress", state.applicantaddress);
            formData.append("CenterAddress", state.centeraddress);
            formData.append("RegionId", regionId.value);
            formData.append("DistrictId", districtId.value);
            formData.append("LandDescription", state.landdescription);
            formData.append("DayTripService", dayTripService);
            formData.append("Accomodation", accomodation);
            formData.append("PlayArea", playArea);
            formData.append("AdventureGames", adventureGames);
            formData.append("RuralGames", ruralGames);
            formData.append("AgriculturalCamping", agriculturalCamping);
            formData.append("HorticultureSale", horticultureSale);
            formData.append("LiveInPlace", liveInPlace.value);
            formData.append("GreenHouse", greenHouse);
            formData.append("MilkBusiness", milkBusiness);
            formData.append("Fisheries", fisheries);
            formData.append("RopVatikaNursery", ropVatikaNursery);
            formData.append("AnimalBirdRearing", animalBirdRearing);
            formData.append("NatureAdventureTourism", natureAdventureTourism);
            formData.append("Other", other);
            formData.append("CenterStarted", state.centerstarted);
            formData.append("SignatureFile", signatureFile);
            formData.append("LandDocumentFile", landFile);
            formData.append("CertificateOfRegistrationFile", certificateOfRegistrationFile);
            formData.append("AuthorizationLetterFile", authorizationLetterFile);
            formData.append("PANFile", panFile);
            formData.append("AadharFile", adharFile);
            formData.append("ChallanFile", challanFile);
            formData.append("ElecricityBillFile", elecricityBillFile);
            formData.append("LicenceFile", licenceFile);
            formData.append("BuildingPermissionFile", buildingPermissionFile);
            formData.append("DeclarationFile", declarationFile);
            formData.append("ZoneCertificate", zoneCertificate);
            formData.append("RenewalStatus", status);

            fetch(
                service.ADD_UPDATE_AGRO_TOUR_REGISTRATION,
                {
                    method: 'POST',
                    body: formData,
                    headers: {                  
                        'XApiKey': apiKey
                    },
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert("Congratulations! Your application has been submitted successfully.");
                        navigate('/agrotourregistrationsuccess');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <div style={{ width: '100%' }}>
            {/* <DashboardNavbar /> */}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Agro Tourism Registration
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan="2" align="center" style={{ fontWeight: 'bold' }}>
                                                    Agro Tourism Scheme For Tourists
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" align="center" style={{ fontWeight: 'bold' }}>
                                                    Payment Gateway
                                                    <br />
                                                    To complete the payment visit the following link
                                                    <br />
                                                    <a href="https://gras.mahakosh.gov.in" rel="noreferrer" target="_blank">https://gras.mahakosh.gov.in</a>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" align="left" style={{ fontWeight: 'bold' }}>
                                                    * Required
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '45%' }}>
                                                    <MDInput label="Email Id" fullWidth value={state.emailid || ''}
                                                        name="emailid"
                                                        onChange={changeHandler}
                                                        id="emailid"
                                                        required
                                                        disabled={window.sessionStorage.getItem('userData')}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailid"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Mobile No." fullWidth value={state.mobileno || ''}
                                                        name="mobileno"
                                                        onChange={changeHandler}
                                                        id="mobileno"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mobileno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <MDInput label="Full Name of Applicant/Farmer/Company/Firm/Farmer Co-operative Society And Authorized Person"
                                                        fullWidth value={state.fullname || ''}
                                                        name="fullname"
                                                        onChange={changeHandler}
                                                        id="fullname"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fullname"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <MDInput label="Name of Agro Tourism Center"
                                                        fullWidth value={state.companyname || ''}
                                                        name="companyname"
                                                        onChange={changeHandler}
                                                        id="companyname"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".companyname"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <Select
                                                        options={applicantTypeData}
                                                        name="applicantType"
                                                        value={applicantType}
                                                        onChange={applicantTypeChangeHandler}
                                                        placeholder="Select Applicant Type"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicantType"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="Full Address of Applicant" fullWidth value={state.applicantaddress || ''}
                                                        name="applicantaddress"
                                                        onChange={changeHandler}
                                                        id="applicantaddress"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicantaddress"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Full Address of Agrotorism Center if Different from Applicant Address"
                                                        fullWidth value={state.centeraddress || ''}
                                                        name="centeraddress"
                                                        onChange={changeHandler}
                                                        id="centeraddress"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".centeraddress"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select
                                                        options={regionData}
                                                        name="region"
                                                        value={regionId}
                                                        onChange={regionChangeHandler}
                                                        placeholder="Select Region"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".region"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select className="basic-single"
                                                        options={districtData}
                                                        name="district"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                        isSearchable
                                                        placeholder="Select District"
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <MDInput label="Land Description" fullWidth value={state.landdescription || ''}
                                                        name="landdescription"
                                                        onChange={changeHandler}
                                                        id="landdescription"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".landdescription"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    Type of Agro-tourism Center: Details of Additional Facilities if Available
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={dayTripService} value={dayTripService}
                                                        onChange={(e) => handleDayTripServiceCheckboxChange(e.target.checked)}
                                                        id="chkDayTripService" />
                                                    Day Trip Service
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox defaultChecked={accomodation} value={accomodation}
                                                        onChange={(e) => handleAccomodationCheckboxChange(e.target.checked)}
                                                        id="chkAccomodation" />
                                                    Accomodation
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    Recreational Service
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={playArea} value={playArea}
                                                        onChange={(e) => handlePlayAreaCheckboxChange(e.target.checked)}
                                                        id="chkPlayArea" />
                                                    Play Area For Children
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox defaultChecked={adventureGames} value={adventureGames}
                                                        onChange={(e) => handleAdventureGamesCheckboxChange(e.target.checked)}
                                                        id="chkAdventureGames" />
                                                    Adventure Games
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={ruralGames} value={ruralGames}
                                                        onChange={(e) => handleRuralGamesCheckboxChange(e.target.checked)}
                                                        id="chkRuralGames" />
                                                    Rural Games
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={agriculturalCamping} value={agriculturalCamping}
                                                        onChange={(e) => handleAgriculturalCampingCheckboxChange(e.target.checked)}
                                                        id="chkAgriculturalCamping" />
                                                    Agricultural Camping
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox defaultChecked={horticultureSale} value={horticultureSale}
                                                        onChange={(e) => handleHorticultureSaleCheckboxChange(e.target.checked)}
                                                        id="chkHorticultureSale" />
                                                    Horticulture and Product Sale
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Do Applicant live in Agro-tourism place?
                                                    <Select
                                                        options={liveInPlaceData}
                                                        name="liveInPlace"
                                                        value={liveInPlace}
                                                        onChange={liveInPlaceChangeHandler}
                                                        placeholder="Select"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".liveInPlace"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2" style={{ fontWeight: 'bold' }}>
                                                    Other Activities Related to Agriculture
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={greenHouse} value={greenHouse}
                                                        onChange={(e) => handleGreenHouseCheckboxChange(e.target.checked)}
                                                        id="chkGreenHouse" />
                                                    Green House
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox defaultChecked={milkBusiness} value={milkBusiness}
                                                        onChange={(e) => handleMilkBusinessCheckboxChange(e.target.checked)}
                                                        id="chkMilkBusiness" />
                                                    Milk Business
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={fisheries} value={fisheries}
                                                        onChange={(e) => handleFisheriesCheckboxChange(e.target.checked)}
                                                        id="chkFisheries" />
                                                    Fisheries
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox defaultChecked={ropVatikaNursery} value={ropVatikaNursery}
                                                        onChange={(e) => handleRopVatikaNurseryCheckboxChange(e.target.checked)}
                                                        id="chkRopVatikaNursery" />
                                                    RopVatika
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={animalBirdRearing} value={animalBirdRearing}
                                                        onChange={(e) => handleAnimalBirdRearingCheckboxChange(e.target.checked)}
                                                        id="chkAnimalBirdRearing" />
                                                    Animal-Bird Rearing
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox defaultChecked={natureAdventureTourism} value={natureAdventureTourism}
                                                        onChange={(e) => handleNatureAdventureTourismCheckboxChange(e.target.checked)}
                                                        id="chkNatureAdventureTourism" />
                                                    Nature Adventure Tourism
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Checkbox defaultChecked={other} value={other}
                                                        onChange={(e) => handleOtherCheckboxChange(e.target.checked)}
                                                        id="chkOther" />
                                                    Other
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan="2">
                                                    <MDInput label="If Agro-tourism Center already exists, Information on when it started"
                                                        fullWidth value={state.centerstarted || ''}
                                                        name="centerstarted"
                                                        onChange={changeHandler}
                                                        id="centerstarted"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".centerstarted"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>Applicants Signature / Stamp (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="signatureFile"
                                                        type="file"
                                                        onChange={ev => setSignatureFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".signatureFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>Land Documents of the Applicant (7/12 excerpts, 8a) (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="landFile"
                                                        type="file"
                                                        onChange={ev => setLandFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".landFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Certificate of Registration (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="certificateOfRegistrationFile"
                                                        type="file"
                                                        onChange={ev => setCertificateOfRegistrationFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".certificateOfRegistrationFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Authorization Letter (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="authorizationLetterFile"
                                                        type="file"
                                                        onChange={ev => setAuthorizationLetterFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".authorizationLetterFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>PAN Card (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="panFile"
                                                        type="file"
                                                        onChange={ev => setPanFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".panFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span> Aadhar Card (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="adharFile"
                                                        type="file"
                                                        onChange={ev => setAdharFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".adharFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>Registration Fee Challan (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="challanFile"
                                                        type="file"
                                                        onChange={ev => setChallanFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".challanFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>Electricity Bill (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="elecricityBillFile"
                                                        type="file"
                                                        onChange={ev => setElecricityBillFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".elecricityBillFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span> Licence under Food Security Act (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="licenceFile"
                                                        type="file"
                                                        onChange={ev => setLicenceFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".licenceFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Building Persmission (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="buildingPermissionFile"
                                                        type="file"
                                                        onChange={ev => setBuildingPermissionFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".buildingPermissionFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    <span style={{ color: 'red' }}>*</span>Declaration Form (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="declarationFile"
                                                        type="file"
                                                        onChange={ev => setDeclarationFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <a href={AgroTourDeclaration} download="Declaration.pdf"> Download Format </a>
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".declarationFile"]}</div>
                                                </TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Zone Certificate (File Size 5 MB)
                                                    <br />
                                                    <TextField
                                                        id="zoneCertificate"
                                                        type="file"
                                                        onChange={ev => setZoneCertificateFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".zoneCertificate"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            {/* <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }}>
                                                    Download Declaration Form
                                                    <a href={AgroTourDeclaration} download="Declaration.pdf"> Download Here </a>
                                                </TableCell>
                                            </TableRow> */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Table style={{ width: '20%' }}>
                                    <TableRow>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                {loading ? <Oval
                                                    heigth="20"
                                                    width="20"
                                                    color='grey'
                                                    ariaLabel='loading'
                                                /> :
                                                    <MDButton color="info" onClick={submitHandler}>
                                                        SAVE
                                                    </MDButton>
                                                }
                                                {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                            </MDBox>
                                        </TableCell>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                <MDButton color="secondary" onClick={backHandler}>
                                                    Back
                                                </MDButton>
                                            </MDBox>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

            <Footer />
        </div>
    );
}

export default AgroTourRegistration;