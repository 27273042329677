/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { TextField } from "@mui/material";
// import Icon from "@mui/material/Icon";
// Authentication layout components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import { Oval } from 'react-loader-spinner';
import * as service from '../../services/service';

function ViewAccomodationRegistration() {
    const [state, setState] = useState({ certificatefilename: '', certificatefilepath: '' });
    const [data, setData] = useState([{}]);
    const [documentData, setDocuemntData] = useState([{}]);
    const [siteData, setSiteData] = useState([{}]);
    const [siteStatus, setSiteStatus] = useState('');
    const [siteVerificationDate, setSiteVerificationDate] = useState('');
    const [expectedCompletionDate, setExpectedCompletionDate] = useState('');
    const [remainingDays, setRemainingDays] = useState('');
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState('');
    const [errors, setErrors] = useState({});
    const apiKey = process.env.REACT_APP_API_KEY;
    const navigate = useNavigate();
    const params = useParams();

    const getRegistrationById = () => {
        setLoading(true);
        // const errs = {};              
        if (params.id > 0) {
            fetch(
                service.GET_ACCOMODATION_REGISTRATION_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'XApiKey': apiKey
                    },
                    body: JSON.stringify({
                        AccomodationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    const getDocumentsById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_ACCOMODATION_DOCUMENT_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'XApiKey': apiKey
                    },
                    body: JSON.stringify({
                        AccomodationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result[0].message.messageType === 'Success') {
                        setDocuemntData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    const getSiteVisitDetails = () => {
        setLoading(true);
        fetch(
            service.GET_ACCOMODATION_SITE_VISIT_DETAILS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    AccomodationId: params.id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result[0].message.messageType === "Failed") {
                    setSiteStatus('Pending');
                }
                else {
                    setSiteData(result);
                    setSiteStatus(result[0].siteStatus);
                    if (result[0].siteStatus === 'In Process') {
                        setSiteVerificationDate(new Date(result[0].siteVerificationDate));
                        setExpectedCompletionDate(new Date(result[0].expectedCompletionDate));
                        setRemainingDays(result[0].remainingDays);
                    }
                }
            });
        setLoading(false);
    };

    const getCertificateById = () => {
        fetch(
            service.GET_ACCOMODATION_CERTIFICATE_BY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    AccomodationId: params.id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.message.messageType === 'Success') {
                    setState({
                        ...state, certificatefilename: result.fileName, certificatefilepath: result.filePath,
                    });
                }
            });
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        getRegistrationById();
        getDocumentsById();
        getSiteVisitDetails();
        getCertificateById();
        setLoading(false);
    }, []);

    const backHandler = () => {
        navigate('/authentication/sign-in/basic');
    };

    const handleDownload = (fileName, filePath) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FilePath", filePath);
        fetch(
            service.DOWNLOAD_FILE,
            {
                method: 'POST',
                body: formData,
                headers: {
                    'XApiKey': apiKey
                },
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

        setLoading(false);
    };

    function getExtension(filename) {
        return filename.split('.').pop()
    }
    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!file) {
            isValid = false;
            errs[".file"] = "Please upload file";
        }
        if (file && getExtension(file.name).toLowerCase() !== 'pdf'
            && getExtension(file.name).toLowerCase() !== 'jpg'
            && getExtension(file.name).toLowerCase() !== 'jpeg'
            && getExtension(file.name).toLowerCase() !== 'png'
            && getExtension(file.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".file"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        setErrors(errs);
        return isValid;
    }

    const submitHandler = (e, docName, docId) => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            const formData = new FormData();
            formData.append("AccomodationId", params.id);
            formData.append("AccomodationDocumentId", docId);
            formData.append(docName, file);

            fetch(
                service.UPDATE_ACCOMODATION_DOCUMENT_BY_ID,
                {
                    method: 'POST',
                    body: formData,
                    headers: {                  
                        'XApiKey': apiKey
                    },
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        window.location.reload();
                        // navigate('/agrotourregistrationsuccess');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }
    return (
        <div style={{ width: '100%' }}>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={2}
                                mt={-3}
                                p={2}
                                mb={1}
                                textAlign="center"
                            >
                                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                    Accomodation Registration For Industrial Status Details
                                </MDTypography>
                            </MDBox>
                            {loading ? <Oval
                                heigth="100"
                                width="100"
                                color='grey'
                                ariaLabel='loading'
                            /> :
                                <MDBox pt={4} pb={3} px={3}>
                                    <MDBox component="form" role="form">
                                        {/* <div style={{ fontSize: '12px', color: 'red' }}>{errors}</div> */}
                                        <Card style={{ alignItems: 'center' }}>
                                            <MDBox pt={4} pb={3} px={3} style={{ width: '100%' }}>
                                                <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
                                                    <Table id="tblReceipt" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell colSpan={2}>
                                                                    <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Email Id :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.emailId}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Mobile No. :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.mobileNo}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Registration Date :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {moment(data.addedDate).format("DD/MM/YYYY")}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Name of Hotel / Resort :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.hotelName}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Name of Authorized Person :
                                                                            </TableCell>
                                                                            <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.authorizedPerson}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Full Address :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.address}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Pin Code :
                                                                            </TableCell>
                                                                            <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.pinCode}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Region :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.region}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                District :
                                                                            </TableCell>
                                                                            <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.district}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Hotel Address :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.hotelAddress}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Emergency Contact No. :
                                                                            </TableCell>
                                                                            <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.emergencyContactNo}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Applicant Type :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.applicantType}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Total Area in Sq.Mt. :
                                                                            </TableCell>
                                                                            <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.areaSqMt}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Total Number of Rooms :
                                                                            </TableCell>
                                                                            <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.roomNo}
                                                                            </TableCell>
                                                                            <TableCell width="15%" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Total Number of Employees :
                                                                            </TableCell>
                                                                            <TableCell width="20%" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.employeeNo}
                                                                            </TableCell>
                                                                            <TableCell width="15%" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Commencement Date :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {moment(data.commencementDate).format("DD/MM/YYYY")}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                General Requirements :
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Minimum 6 lettable rooms, all rooms with outside windows / ventilation :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.rooms ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Minimum Rooms size should be as follows (Room size excludes bathroom) All rooms must have attached bathroom mandatorily - Single - 80 sq. ft. and Double - 120 sq. fL :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.roomSize ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Minimum Bathroom size should be 30 sq. ft. :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.bathroomSize ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Bathroom Sanitary Fixtures Toilets must be well ventilated. Each western WC toilet should have a seat with lid and toilet paper. Post toilet hygiene facilities - toilet paper, soap, sanitary bin, 24-hour running water. :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.sanitaryFixtures ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Full time operation 7 days a week :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.fullTimeOperation ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                24 hrs. elevators for buildings higher than ground plus four floors or as per the prevailing local building norms applicable :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.elevator ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                24x7 availability of electricity :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.electricity ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Emergency lights available in the public areas :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.emergencyLights ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Hotel Entrances and all common areas are controlled by CCTV Cameras 24 hours a day :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.cCTV ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Differently Abled Guest Friendly Access at the entrance :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.guestAccess ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Security Guards available 24 hours a day :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.securityGuards ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Bathroom
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Rooms with attached bathrooms :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.attachedBathroom ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Availability of Hot and Cold running water :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.runningWater ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Water saving taps and showers :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.tapsShowers ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Public Area
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Lounge or seating area in the lobby :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.lounge ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Reception facility :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.reception ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Public restrooms with a wash basin, a mirror, a sanitary bin with lid in unisex toilet :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.restRoom ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Room And Facilities for the Differently Abled Guests
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Atleast one room for the differently able guest :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.guestRoom ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Kitchen / Food Production Area
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                FSSAI registration / Licensed Kitchen :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.kitchen ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Hotel Staff And Related Facilities
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Uniforms for front house staff :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.uniform ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Code of Conduct for Safe And Honorable Tourism
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Display of pledge :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.pledge ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Maintenance of Complaint book and Suggestion Book :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.complaintBook ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Appointment of Nodal officers and display of information of Nodal officer at reception :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.nodalOfficer ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Guest Services
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Availability of Doctor-on-call service and name address and telephone number of doctors with front desk :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.doctorOnCall ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Safety And Security
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Police Verification for employees :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.policeVerification ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Conducting regular fire fighting drills and adherence to norms of the Fire department :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.fireDrills ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                First aid kit at the front desk :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.firstAidKit ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Additional Features
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Suite (2 rooms or 2 room- bays having a bedroom and separate sitting area, having one bathroom and one powder room) :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.suite ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                F and B Outlet :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.fBOutlet ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Iron and Iron Board facility :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.ironBoard ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Paid transportation on call :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.paidTransportation ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Business Center :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.businessCenter ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Conference Facilities :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.conference ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Sewage Treatment Plant :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.sewageTreatment ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Rainwater Harvesting :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.rainwaterHarvesting ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Other :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.other ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Electricity Company Name And Address  :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.electricityCompanyDetails}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Property Tax Dept Name And Address :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.propertyTaxDeptDetails}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Water Bill Dept Name And Address
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.waterBillDeptDetails}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                MSEB Consumer Number :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.msebConsumerNo}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Star Category :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.starCategory}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                DoT Status
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.status}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Assistant Director Status
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.assistantDirectorStatus}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Joint Director Status
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.jointDirectorStatus}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Director Status
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.directorStatus}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Remarks
                                                                            </TableCell>
                                                                            <TableCell colSpan="6" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.remarks}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </Table>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                    <Table>
                                                        <TableRow>
                                                            <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Documents
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Document Name
                                                            </TableCell>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                File Name
                                                            </TableCell>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Status
                                                            </TableCell>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Verification Date
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Upload
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableBody>
                                                            {documentData.map((row) => (
                                                                <TableRow
                                                                    key={row.accomodationDocumentId}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        <MDButton onClick={() => handleDownload(row.fileName, row.filePath)}>{row.documentName}</MDButton>
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.fileName}</TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.status}</TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.verificationDate ? moment(row.verificationDate).format("DD/MM/YYYY") : ''}</TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {row.status === "Rejected" ?
                                                                            <div>
                                                                                <TextField
                                                                                    id="file"
                                                                                    type="file"
                                                                                    onChange={ev => setFile(ev.target.files[0])}
                                                                                    style={{ width: 300 }}
                                                                                    required
                                                                                />
                                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".file"]}</div>
                                                                                <MDButton color="info" onClick={e => submitHandler(e, row.documentName, row.accomodationDocumentId)}>
                                                                                    Save
                                                                                </MDButton>
                                                                            </div>
                                                                            : null}

                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                    <Table>
                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Site Visit Status
                                                        </TableCell>
                                                        <TableRow>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Criteria</TableCell>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Remarks</TableCell>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Status</TableCell>
                                                        </TableRow>
                                                        <TableBody>
                                                            {siteData.map((row) => (
                                                                <TableRow
                                                                    key={row.criteriaId}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.criteria}</TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {row.remarks}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {row.status}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                            <TableRow>
                                                                <TableCell colSpan="3" style={{ fontSize: '14px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Site Visit Status : {siteStatus} </TableCell>
                                                            </TableRow>
                                                            {siteStatus === 'In Process' ?
                                                                <TableRow>
                                                                    <TableCell style={{ fontSize: '14px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Site Verification Date : {moment(siteVerificationDate).format('DD/MM/YYYY')} </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Expected Completion Date : {moment(expectedCompletionDate).format('DD/MM/YYYY')} </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Remaining Days : {remainingDays} </TableCell>
                                                                </TableRow>
                                                                : null}
                                                            {siteStatus === 'In Process' ?
                                                                <TableRow>
                                                                    <TableCell colSpan="3" style={{ fontSize: '14px', color: 'red', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Kindly complete the pending Site Verification criteria whose Status has been marked `NO` on or before expected completion date. <br /> If you do not fulfil them on or before expected completion date, your application will get rejected.</TableCell>
                                                                </TableRow>
                                                                : null}
                                                        </TableBody>
                                                    </Table>
                                                    <Table>
                                                        <TableRow>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Certificate File
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {state.certificatefilename ?
                                                                    <MDButton onClick={() => handleDownload(state.certificatefilename, state.certificatefilepath)}>{state.certificatefilename}</MDButton>
                                                                    : null}
                                                            </TableCell>
                                                        </TableRow>
                                                    </Table>
                                                </TableContainer>
                                            </MDBox>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                <Table>
                                                    <TableRow>
                                                        <TableCell>
                                                            <MDButton color="secondary" onClick={backHandler}>
                                                                Log Out
                                                            </MDButton>
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </MDBox>
                                        </Card>
                                    </MDBox>
                                </MDBox>

                            }
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </div>
    );
}

export default ViewAccomodationRegistration;
