/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// import Icon from "@mui/material/Icon";
// Authentication layout components
import { TextField } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import { Oval } from 'react-loader-spinner';
import * as service from '../../services/service';

function ViewAgroTourRegistration() {
    const [state, setState] = useState({ certificatefilename: '', certificatefilepath: '' });
    const [data, setData] = useState([{}]);
    const [documentData, setDocuemntData] = useState([{}]);
    const [siteData, setSiteData] = useState([{}]);
    const [siteStatus, setSiteStatus] = useState('');
    const [siteVerificationDate, setSiteVerificationDate] = useState('');
    const [expectedCompletionDate, setExpectedCompletionDate] = useState('');
    const [remainingDays, setRemainingDays] = useState('');
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState('');
    const [errors, setErrors] = useState({});
    const apiKey = process.env.REACT_APP_API_KEY;
    const navigate = useNavigate();
    const params = useParams();

    const getRegistrationById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_AGRO_TOUR_REGISTRATION_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'XApiKey': apiKey
                    },
                    body: JSON.stringify({
                        AgroRegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    const getDocumentsById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_AGRO_TOUR_DOCUMENT_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'XApiKey': apiKey
                    },
                    body: JSON.stringify({
                        AgroRegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result[0].message.messageType === 'Success') {
                        setDocuemntData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    const getSiteVisitDetails = () => {
        setLoading(true);
        fetch(
            service.GET_AGRO_TOUR_SITE_VISIT_DETAILS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    AgroRegistrationId: params.id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result[0].message.messageType === "Failed") {
                    setSiteStatus('Pending');
                }
                else {
                    setSiteData(result);
                    setSiteStatus(result[0].siteStatus);
                    if (result[0].siteStatus === 'In Process') {
                        setSiteVerificationDate(new Date(result[0].siteVerificationDate));
                        setExpectedCompletionDate(new Date(result[0].expectedCompletionDate));
                        setRemainingDays(result[0].remainingDays);
                    }
                }
            });
        setLoading(false);
    };

    const getCertificateById = () => {
        fetch(
            service.GET_AGRO_CERTIFICATE_BY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'XApiKey': apiKey
                },
                body: JSON.stringify({
                    AgroRegistrationId: params.id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.message.messageType === 'Success') {
                    setState({
                        ...state, certificatefilename: result.fileName, certificatefilepath: result.filePath,
                    });
                }
            });
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        getRegistrationById();
        getDocumentsById();
        getSiteVisitDetails();
        getCertificateById();
        setLoading(false);
    }, []);

    const backHandler = () => {
        navigate('/authentication/sign-in/basic');
    };

    const handleDownload = (fileName, filePath) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FilePath", filePath);
        fetch(
            service.DOWNLOAD_FILE,
            {
                method: 'POST',
                body: formData,
                headers: {                  
                    'XApiKey': apiKey
                },
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

        setLoading(false);
    };

    function getExtension(filename) {
        return filename.split('.').pop()
    }
    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!file) {
            isValid = false;
            errs[".file"] = "Please upload file";
        }
        if (file && getExtension(file.name).toLowerCase() !== 'pdf'
            && getExtension(file.name).toLowerCase() !== 'jpg'
            && getExtension(file.name).toLowerCase() !== 'jpeg'
            && getExtension(file.name).toLowerCase() !== 'png'
            && getExtension(file.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".file"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        setErrors(errs);
        return isValid;
    }

    const submitHandler = (e, docName, docId) => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            const formData = new FormData();
            formData.append("AgroRegistrationId", params.id);
            formData.append("AgroDocumentId", docId);
            formData.append(docName, file);

            fetch(
                service.UPDATE_AGRO_TOUR_DOCUMENT_BY_ID,
                {
                    method: 'POST',
                    body: formData,
                    headers: {                  
                        'XApiKey': apiKey
                    },
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        window.location.reload();
                        // navigate('/agrotourregistrationsuccess');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <div style={{ width: '100%' }}>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={2}
                                mt={-3}
                                p={2}
                                mb={1}
                                textAlign="center"
                            >
                                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                    Agro Tour Registration Details
                                </MDTypography>
                            </MDBox>
                            {loading ? <Oval
                                heigth="100"
                                width="100"
                                color='grey'
                                ariaLabel='loading'
                            /> :
                                <MDBox pt={4} pb={3} px={3}>
                                    <MDBox component="form" role="form">
                                        {/* <div style={{ fontSize: '12px', color: 'red' }}>{errors}</div> */}
                                        <Card style={{ alignItems: 'center' }}>
                                            <MDBox pt={4} pb={3} px={3} style={{ width: '100%' }}>
                                                <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
                                                    <Table id="tblReceipt" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell colSpan={2}>
                                                                    <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Email Id :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.emailId}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Mobile No. :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.mobileNo}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Registration Date :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {moment(data.addedDate).format("DD/MM/YYYY")}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="2" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Full Name of Applicant/Farmer/Company/Firm/Farmer Co-operative Society And Authorized Person :
                                                                            </TableCell>
                                                                            <TableCell colSpan="4" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.fullName}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="2" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Name of Agro Tourism Center :
                                                                            </TableCell>
                                                                            <TableCell colSpan="4" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.companyName}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Applicant Type :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.applicantType}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Full Address of Applicant :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.applicantAddress}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Full Address of Agrotorism Center if Different from Applicant Address :
                                                                            </TableCell>
                                                                            <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.centerAddress}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Region :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.region}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                District :
                                                                            </TableCell>
                                                                            <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.district}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="2" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Description of Complete Agricultural land Available for Agro-tourists under the Scheme (village, area of land(per acre), number of rooms and beds as well as other facilities (toilets / bathrooms / telephone etc.))  :
                                                                            </TableCell>
                                                                            <TableCell colSpan="4" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.landDescription}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Type of Agro-tourism Center: Details of Additional Facilities if Available :
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Day Trip Service :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.dayTripService ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Accomodation :
                                                                            </TableCell>
                                                                            <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.accomodation ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Recreational Service
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Play Area For Children :
                                                                            </TableCell>
                                                                            <TableCell width="20%" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.playArea ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell width="15%" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Adventure Games :
                                                                            </TableCell>
                                                                            <TableCell width="20%" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.adventureGames ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell width="15%" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Rural Games :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.ruralGames ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Agricultural Camping :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.agriculturalCamping ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Horticulture and Product Sale :
                                                                            </TableCell>
                                                                            <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.horticultureSale ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Do Applicant live in Agro-tourism place? :
                                                                            </TableCell>
                                                                            <TableCell colSpan="5" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.liveInPlace ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Other Activities Related to Agriculture
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Green House :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.greenHouse ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Milk Business :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.milkBusiness ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Fisheries :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.fisheries ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                RopVatika :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.ropVatikaNursery ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Animal-Bird Rearing :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.animalBirdRearing ? "Yes" : "No"}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Nature Adventure Tourism :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.natureAdventureTourism ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Other :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.other ? "Yes" : "No"}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell colSpan="2" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                If Agro-tourism Center already exists, Information on when it started  :
                                                                            </TableCell>
                                                                            <TableCell colSpan="2" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.centerStarted}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Status :
                                                                            </TableCell>
                                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.status}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Remarks
                                                                            </TableCell>
                                                                            <TableCell colSpan="6" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.remarks}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </Table>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                    <Table>
                                                        <TableRow>
                                                            <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Documents
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Document Name
                                                            </TableCell>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                File Name
                                                            </TableCell>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Status
                                                            </TableCell>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Verification Date
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Upload
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableBody>
                                                            {documentData.map((row) => (
                                                                <TableRow
                                                                    key={row.agroDocumentId}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        <MDButton onClick={() => handleDownload(row.fileName, row.filePath)}>{row.documentName}</MDButton>
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.fileName}</TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.status}</TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.verificationDate ? moment(row.verificationDate).format("DD/MM/YYYY") : ''}</TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {row.status === "Rejected" ?
                                                                            <div>
                                                                                <TextField
                                                                                    id="file"
                                                                                    type="file"
                                                                                    onChange={ev => setFile(ev.target.files[0])}
                                                                                    style={{ width: 300 }}
                                                                                    required
                                                                                />
                                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".file"]}</div>
                                                                                <MDButton color="info" onClick={e => submitHandler(e, row.documentName, row.agroDocumentId)}>
                                                                                    Save
                                                                                </MDButton>
                                                                            </div>
                                                                            : null}

                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                    <Table>
                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Site Visit Status
                                                        </TableCell>
                                                        <TableRow>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Criteria</TableCell>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Remarks</TableCell>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Status</TableCell>
                                                        </TableRow>
                                                        <TableBody>
                                                            {siteData.map((row) => (
                                                                <TableRow
                                                                    key={row.criteriaId}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.criteria}</TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {row.remarks}
                                                                    </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {row.status}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                            <TableRow>
                                                                <TableCell colSpan="3" style={{ fontSize: '14px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Site Visit Status : {siteStatus} </TableCell>
                                                            </TableRow>
                                                            {siteStatus === 'In Process' ?
                                                                <TableRow>
                                                                    <TableCell style={{ fontSize: '14px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Site Verification Date : {moment(siteVerificationDate).format('DD/MM/YYYY')} </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Expected Completion Date : {moment(expectedCompletionDate).format('DD/MM/YYYY')} </TableCell>
                                                                    <TableCell style={{ fontSize: '14px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Remaining Days : {remainingDays} </TableCell>
                                                                </TableRow>
                                                                : null}
                                                            {siteStatus === 'In Process' ?
                                                                <TableRow>
                                                                    <TableCell colSpan="3" style={{ fontSize: '14px', color: 'red', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Kindly complete the pending Site Verification criteria whose Status has been marked `NO` on or before expected completion date. <br /> If you do not fulfil them on or before expected completion date, your application will get rejected.</TableCell>
                                                                </TableRow>
                                                                : null}
                                                        </TableBody>
                                                    </Table>
                                                    <Table>
                                                        <TableRow>
                                                            <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Certificate File
                                                            </TableCell>
                                                            <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                {state.certificatefilename ?
                                                                    <MDButton onClick={() => handleDownload(state.certificatefilename, state.certificatefilepath)}>{state.certificatefilename}</MDButton>
                                                                    : null}
                                                            </TableCell>
                                                        </TableRow>
                                                    </Table>
                                                </TableContainer>
                                            </MDBox>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                <Table>
                                                    <TableRow>
                                                        <TableCell>
                                                            <MDButton color="secondary" onClick={backHandler}>
                                                                Log Out
                                                            </MDButton>
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </MDBox>
                                        </Card>
                                    </MDBox>
                                </MDBox>

                            }
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </div>
    );
}

export default ViewAgroTourRegistration;
